export const Enums = {
  VOTING: "VOTING",
  AIJUDGE: "AIJUDGE",
  COACHJUDGE: "COACHJUDGE",
  NOJUDGE: "NO JUDGE",
  LIVE_TAB: "LIVE_TAB",
  MATCH_TIED: "MATCH_TIED",
  UPCOMING_TAB: "UPCOMING_TAB",
  WON: "WON",
  LOSE: "LOSE",
  TIED: "MATCH_TIED",
  TRANSCRIPT_TAB: "TRANSCRIPT_TAB",
  MYSTERY_TAB: "MYSTERY_TAB",
  UPDATED_DEBATE: "UPDATED_DEBATE",
  DEBATE_STATE: "DEBATE_STATE",
  USER_FORM: "USER_FORM",
  MOTION_FORM: "MOTION_FORM",
  MOTION_CAT_FORM: "MOTION_CAT_FORM",
  CHANGES_DEBATES: "CHANGES_DEBATES",
  PRIVATE_DEBATE: "PRIVATE_DEBATE",
  PUBLIC_DEBATE: "PUBLIC_DEBATE",
  AUDIENCE: "AUDIENCE",
  DID_NOT_SPEAK: "This team has not speak anything",
  INVALID: "INVALID",
  COACH_JUDGE_SESSION: "COACH_JUDGE_SESSION",
  
};

