import { MdOutlineAdd, MdModelTraining } from "react-icons/md";
import { HiOutlineViewfinderCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import "./Banner.css";
import DebatePasscodeInput from "../../components/DebateForm/TeamForm/DebatePasscodeInput/DebatePasscodeInput";
import { debaiInfoApi, debateInfoApi } from "../../utils/Api";
const Banner = () => {
  const [debateLink, setdebateLink] = useState("");
  const { data: userData } = useSelector((state) => state.user);
  const [validLink, setValidLink] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [debaiInfo,setDebaiInfo] =useState({})
  useEffect(() => {
    let id = debateLink?.split("?")[1]?.split("=")[1];
    if (id) {
      setValidLink(true);
    } else {
      setValidLink(false);
    }
  }, [debateLink]);
  useEffect(()=>{
    handleGetInfo()
  },[])

  const handleGetInfo=async()=>{
    try {
      const {status,data} = await debaiInfoApi()
      if(status===200){
        setDebaiInfo(data.message[0])

      }
    } catch (error) {
        console.log(error)
    }
  }

  const handleWatch = () => {
    if (!validLink) {
      toast({
        title: "",
        description: "Invalid Debate Link",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
      return;
    }
    if (debateLink) {
      let debateId = debateLink.split("=")[1];
      navigate(`/watch?debateId=${debateId}`);
    }
  };
  const handleCreateDebate = () => {

    if (!userData) {
      navigate("/login");
      // handleShowToastBar("You need to log in first ");
    } else if (!userData?.subscription?.active && !userData?.isCoach && !userData?.isAdmin) {
      navigate("/subscription");
    } else {
      // this is how the user is redirected to the next page 
      // in this case user will be redirected to the /create page 
      navigate(`/create`);
    }
  };
  
  const handleShowToastBar = (text) => {
    toast({
      title: "",
      description: text,
      status: "error",
      duration: 5000,
      position: "top",
      isClosable: true,
    });
  };
  return (
    <div className="BannerWrapper">
      <img className="robo roboLeft" src="/images/robo1.png" alt="roboImg" />
      <img className="robo roboRight" src="/images/robo2.png" alt="roboImg" />
      {/* <img className="logo_second" src="/images/banner_logo.jpeg" alt="logo2" /> */}
      <div className="banner_content">
        {/*<h1 className="weeklyTopicText"> Debate of the week</h1>*/}
        <marquee  className="main_text_banner">
          {debaiInfo?.weeklyDebate}
        </marquee>
      </div>
      <div className="banne_bottom_option_box">
        <button
          onClick={handleCreateDebate}
          className="banner_bottom_button_option_button"
        >
          <MdOutlineAdd />
          <p>Create New Debate</p>
        </button>

        <a href="https://chat.openai.com" target="_blank">
          <button className="prepare_for_debate_btn">
            <MdModelTraining />
            <p>Prepare With ChatGPT</p>
          </button>
        </a>
      </div>
      <DebatePasscodeInput />
    </div>
  );
};

export default Banner;
