import "./NoneJoined.css"
const NoneJoined = ({ team,roboImg }) => {
    return (
        <div className='noneJoinedBox'>
            <div className="noUsersBox">
                <p>Waiting for team...</p>
            </div>
            {/* <img width={"60px"} src={roboImg} alt="teamMembers" /> */}
        </div>
    )
}

export default NoneJoined