import React, { createRef, useEffect, useState } from "react";
import styles from "./DebatePasscodeInput.module.css";
import { HiOutlineViewfinderCircle } from "react-icons/hi2";
import { getDebateByPassocde } from "../../../../utils/Api";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AmIParticipants } from "../../../../utils/services";
import useAlert from "../../../../hook/useAlert";

const DebatePasscodeInput = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { data: currentUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const otpInputs = Array.from({ length: 6 }, () => createRef(null));
  const { open } = useAlert();

  const handleKeyUp = (index, e) => {
    if (
      e.target.value.length === e.target.maxLength &&
      index < otpInputs.length - 1
    ) {
      otpInputs[index + 1].current.focus();
    }
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value.length > 1) {
      return;
    }

    otpInputs[index].current.value = value;
    if (value.length === 1 && index < otpInputs.length - 1) {
      otpInputs[index + 1].current.focus();
    }
  };

  useEffect(() => {
    const handleBackspace = (index, e) => {
      if (e.key === "Backspace" && index > 0 && e.target.value === "") {
        otpInputs[index - 1]?.current?.focus();
      }
    };

    const handlePaste = (e) => {
      const pasteData = e.clipboardData
        .getData("text")
        .slice(0, otpInputs.length);
      pasteData.split("").forEach((char, index) => {
        if (otpInputs[index]?.current) {
          otpInputs[index].current.value = char;
        }
      });
      const nextInputIndex = Math.min(pasteData.length, otpInputs.length - 1);
      if (otpInputs[nextInputIndex]?.current) {
        otpInputs[nextInputIndex].current.focus();
      }
      e.preventDefault();
    };

    otpInputs.forEach((inputRef, index) => {
      if (inputRef.current) {
        inputRef.current.addEventListener("keydown", (e) =>
          handleBackspace(index, e)
        );
        inputRef.current.addEventListener("paste", handlePaste);
      }
    });

    return () => {
      otpInputs.forEach((inputRef, index) => {
        if (inputRef.current) {
          inputRef.current.removeEventListener("keydown", (e) =>
            handleBackspace(index, e)
          );
          inputRef.current.removeEventListener("paste", handlePaste);
        }
      });
    };
  }, [otpInputs]);

  const handleWatchDebate = async () => {
    let debateCodeInput = "";
    otpInputs.forEach((ref) => {
      debateCodeInput += ref.current.value.toString();
    });
    if (debateCodeInput.length < 6) {
      return;
    }

    setLoading(true);

    try {
      const { data, status } = await getDebateByPassocde(+debateCodeInput);
      setLoading(false);
      if (status === 200) {
        const { message } = data;
        if (message.length === 0) {
          toast({
            title: "",
            description: "No debate found with this passcode ",
            status: "error",
            duration: 5000,
            position: "top",
            isClosable: true,
          });
          return;
        }
        if (
          AmIParticipants(message[0].teams, currentUser?._id) ||
          currentUser?.isCoach || currentUser?.isAdmin
        ) {
          if (!currentUser?.subscription?.active && !currentUser?.isCoach && !currentUser?.isAdmin) {
            return navigate("/subscription");
          } else {
            navigate(`/debate/${message[0].passcode}`);
          }
        } else {
          navigate(`/debate/${message[0].passcode}?audience=true`);
        }
      } else throw new Error("some thing went wrong");
    } catch (error) {
      console.log(error.message);
      toast({
        title: "",
        description: "Invalid passcode ",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <div className={styles.passcode_wrapper}>
      <div className={styles.passcode_input_box}>
        {otpInputs.map((inp, index) => (
          <input
            key={index}
            type="number"
            maxLength="1"
            placeholder="0"
            ref={inp}
            onChange={(e) => handleInputChange(index, e)}
            onKeyUp={(e) => handleKeyUp(index, e)}
          />
        ))}
      </div>
      <div className={styles.passcode_text}>
        Enter your 6 digit code to join or watch!
      </div>
      <button className={styles.watchButton} onClick={handleWatchDebate}>
        <HiOutlineViewfinderCircle className="watch_icon" />
        {loading ? "JOINING" : "JOIN NOW"}
      </button>
    </div>
  );
};

export default DebatePasscodeInput;
