import React, { useEffect, useState } from "react";
import styles from "./DecisionBox.module.css"; // Import CSS file for styling
import useAlert from "../../../hook/useAlert";
import { IoCloseCircleSharp } from "react-icons/io5";
import { debateFormatEnum } from "../../../utils/enum";
import { getOrdinal } from "../../../utils/methods";
import PropTypes from 'prop-types';

function DecisionBox({
  activeDebate,
  result,
  setResult,
  RoomService,
  winTeamName,
  debateState,
  setWinTeamName,
}) {
  const { open } = useAlert();
  const [isReadyToFinalize, setIsReadyToFinalize] = useState(false);

  useEffect(() => {
    if (debateState && activeDebate) {
      if (activeDebate.type === debateFormatEnum["British Parliamentary"]) {
        setIsReadyToFinalize(
          !Object.values(result).some((val) => val === "") &&
            debateState.hasFinished
        );
      } else {
        setIsReadyToFinalize(debateState.hasFinished && winTeamName);
      }
    }
  }, [debateState, activeDebate, result, winTeamName]);

  // Initialize state to store the positions of teams

  // Function to handle dropping a team into a position
  const handleDrop = (position, teamName) => {
    if (isTeamAssigned(teamName)) {
      return open({ type: "error", text: "This team is already assigned." });
    }
    setResult((prevResult) => ({
      ...prevResult,
      [position]: teamName,
    }));
  };

  // Function to allow dropping items
  const allowDrop = (ev) => {
    ev.preventDefault();
     ev.dataTransfer.getData("teamName");
  };

  // Function to handle drag start
  const dragStart = (ev, teamName) => {
    ev.dataTransfer.setData("teamName", teamName);
  };

  // Function to check if the team is already assigned
  const isTeamAssigned = (teamName) => {
    return Object.values(result).includes(teamName);
  };
  const removeTeam = (position) => {
    setResult((prev) => ({
      ...prev,
      [position]: "",
    }));
  };

  if (!activeDebate || !debateState) {
    return <>loading</>;
  }

  // JSX for rendering the debate platform UI
  return (
    <div className={styles.DecisionBox}>
      <p className="text-[17px] text-slate-500">
        {activeDebate?.type === debateFormatEnum["British Parliamentary"]
          ? "Please Drag and drop the team to their position."
          : "Please select the winner team."}
      </p>
      <div className={styles.team_box}>
        {/* Buttons representing each team */}
        {activeDebate?.teams.map((t) => (
          <div
            className={`${styles.team_box_item} ${
              winTeamName === t.name ? styles.selected_team_name : ""
            }`}
            draggable={activeDebate?.judgeType===debateFormatEnum["British Parliamentary"]}
            onDragStart={(e) => dragStart(e, t.name)}
            onClick={() => setWinTeamName(t.name)}
          >
            <img
              width="44"
              height="44"
              src="https://img.icons8.com/3d-fluency/94/user-group-man-man--v4.png"
              alt="user-group-man-man--v4"
            />
            <p>{t.name}</p>
          </div>
        ))}
      </div>
      {
        activeDebate?.type === debateFormatEnum["British Parliamentary"] && (
        <div className={styles.resultBox}>
          {result &&
            Object.keys(result).map((t) => (
              <div
                onDrop={(e) =>
                  handleDrop(Number(t), e.dataTransfer.getData("teamName"))
                }
                onDragOver={(e) => allowDrop(e)}
                className={styles.resultBoxItem}
              >
                {result[t] ? (
                  <>
                    <IoCloseCircleSharp
                      className={styles.removeIcon}
                      onClick={() => removeTeam(t)}
                    />
                    <img
                      width="40"
                      height="40"
                      src="https://img.icons8.com/3d-fluency/94/user-group-man-man--v4.png"
                      alt="user-group-man-man--v4"
                    />
                    <p>{result[t]}</p>
                  </>
                ) : (
                  <p>{getOrdinal(Number(t))}</p>
                )}
              </div>
            ))
          }
        </div>
      )}
      <button
        onClick={()=>RoomService.handleFinalizeCoachDecision()}
        className={`${styles.decisionButton} ${
          isReadyToFinalize ? styles.activeButton : ""
        } `}
      >
        FINALIZE DECISION
      </button>
    </div>
  );
}

export default DecisionBox;

DecisionBox.propTypes = {
  activeDebate: PropTypes.shape({
    type: PropTypes.string.isRequired,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ),
    judgeType: PropTypes.string,
  }),
  result: PropTypes.shape({
    "1": PropTypes.string.isRequired,
    "2": PropTypes.string.isRequired,
    "3": PropTypes.string.isRequired,
    "4": PropTypes.string.isRequired,
  }),
  setResult: PropTypes.func.isRequired,
  RoomService: PropTypes.object.isRequired, // you may need to adjust this according to the actual class structure
  winTeamName: PropTypes.string.isRequired,
  debateState: PropTypes.shape({
    hasFinished: PropTypes.bool.isRequired,
    isStarted: PropTypes.bool.isRequired,
  }),
  setWinTeamName: PropTypes.func.isRequired,
};
