import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import  { io }  from "socket.io-client";
import { Enums } from "../redux/action/actionTypes/Enumss";
import { setDebatesChangesAction } from "../redux/action/actionCreators";


const useSocket =()=>{

      const {data:userData} =useSelector((state)=>state.user);
      const dispatch =useDispatch()


   useEffect(()=>{

   const socket =  io(process.env.REACT_APP_BACKEND_ROOT);

   socket.on(Enums.CHANGES_DEBATES,(data)=>{

    dispatch(setDebatesChangesAction(data))
   })
  },[])


  

}

export default useSocket;