import React from "react";
import TeamForm from "./TeamForm/TeamForm";
import useAlert from "../../hook/useAlert";

const Teams = ({
  formLevel,
  debateForm,
  setDebateForm,
  handleTeamName,
  setTeamNameError,
  teamNameError,
}) => {
  const { open } = useAlert();

  const handleChangeChooseSides = (e) => {
    const end = Date.now() + 1000 * 60 * 15;
    const { startTime } = debateForm;
    if (end >= startTime) {
      open({
        type: "error",
        text: "System unable to randomly decide sides because the debate will start soon",
      });
    } else {
      setDebateForm((prev) => ({ ...prev, chooseSide: e.target.checked }));
    }
  };

  return (
    <div
      className={`team_box_container ${
        formLevel !== 1 && "disable_team_box_container_form"
      } `}
    >
      <div className="team_wrapper_box">
        {debateForm.teams.map((team, index) => (
          <TeamForm
            setTeamNameError={setTeamNameError}
            teamNameError={teamNameError}
            debateForm={debateForm}
            setDebateForm={setDebateForm}
            handleTeamName={handleTeamName}
            team={team}
            index={index}
            key={index}
          />
        ))}
      </div>
      <div className="checkBoxBotton">
        <input
          type="checkbox"
          checked={debateForm.chooseSide}
          onClick={handleChangeChooseSides}
        />
        <p>Sides randomly assigned 15 mins before debate start</p>
      </div>
    </div>
  );
};

export default Teams;
