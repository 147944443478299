import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Td,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import {  getLiveTournamentListApi } from "../../utils/Api";
import moment from "moment";
import { Button } from "flowbite-react";
const TournamentTable = () => {
  const [tournament, setTournament] = useState([]);

  useEffect(() => {
    fetchDebAiUsers();
  }, []);

  const fetchDebAiUsers = async () => {
    try {
      const { data, status } = await getLiveTournamentListApi();
      if (status === 200) {
        setTournament(data.message);
      } else {
        throw data.message;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
      <TableContainer>
          
      <Table variant="simple">
        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>Start Date</Th>
            <Th>Registration Deadline</Th>
            <Th>Judge</Th>
            <Th>Status</Th>
            <Th>Description</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tournament?.map((t) => (
            <Tr>
              <Td> {t._id}</Td>

              <Td> {moment(t.start_date).format("llll")}</Td>
              <Td> {moment(t.registration_deadline).format("llll")}</Td>
              <Td>
                {t.judge_id?.firstName} {t.judge_id?.lastName}
              </Td>
              <Td>Not Started</Td>
              <Td className="max-w-xs truncate ..."> {t.description}</Td>
              <Td>
                <Button outline color={"blue"}>View Details</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TournamentTable;
