import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import styles from "./DebateFinishModal.module.css";

function DebateTranscriptModal({ children, transcript }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnClose = async () => {
    onClose();
  };

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className={styles.finishModalContainer}>
          <ModalCloseButton />

          <ModalBody P={"0px"} textAlign={"center"}>
            <div className={styles.transcript_content}>
              <p>
                {transcript?.map((text) => (
                  <p className={styles.para}>{text}</p>
                ))}
              </p>
            </div>
            <button className={styles.OkeyButton} onClick={handleOnClose}>
              OK
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
export default DebateTranscriptModal;
