import { Box, SkeletonCircle, SkeletonText, Stack } from '@chakra-ui/react'
import React from 'react'

const ProfileCardSktn = () => {
  return (
         <Box padding='6' boxShadow='lg' bg='white'  width={"100%"}  display={"flex"} gap={"2rem"} >
            <Stack gap={""} width={"full"}>
            <Stack direction={"row"} gap={"4px"}>
                <SkeletonCircle size={"70px"} />
                <Stack width={"90%"}>
                   <SkeletonText mt='4' noOfLines={1} spacing='5' skeletonHeight='10' />
                </Stack>
            </Stack>

            <Stack gap={"10px"}>
                
            <SkeletonText mt='4' noOfLines={2} spacing='5' skeletonHeight='7' />
 
            </Stack>
            </Stack>
            <Stack gap={"10px"} width={"full"}>
                
            <SkeletonText width={"full"} mt='4' noOfLines={4} spacing='5' skeletonHeight='8' />
           

            </Stack>
        </Box>
  )
}

export default ProfileCardSktn;