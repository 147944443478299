import { coachData } from "../../utils/data"
import CoachItem from "./CoachItem/CoachItem"
import styles from "./coaches.module.css"

const Coaches = () => {
  return (
    <div className={styles.Coaches}>

<div className={styles.coachContentWrapper}>

      <h1 className={styles.top_header_text}>Top Debai Coaches</h1>
   
    <div  className={styles.coach_container}>
{
  coachData.map((coach,index)=><CoachItem key={index} data={coach}/>)
}
    </div>
  </div>

    </div>
  )
}

export default Coaches