export function containsOnlyCharacters(inputString) {
  // Define the regular expression pattern
  console.log("inside",inputString)
  const regex = /^[a-zA-Z]+$/;
  
  // Use the test() method to check if the inputString matches the pattern
  return regex.test(inputString);
}


  export const getOrdinal = (position) => {
    const num = position % 10;
    switch (num.toString()) {
      case "1":
        return `${position}st`;
      case "2":
        return `${position}nd`;
      case "3":
        return `${position}rd`;

      default:
        return `${position}th`;
    }
  };


  /**
 * Converts a duration in milliseconds to a formatted string.
 *
 * The formatted string shows the duration in days, hours, minutes, and seconds,
 * depending on the length of the duration. For example:
 * - 2days : 20min : 30sec
 * - 1hr : 20min : 30sec
 * - 20min : 30sec
 * - 0min : 30sec
 *
 * @param {number} ms - The duration in milliseconds.
 * @returns {string} A formatted string representing the duration.
 *
 * @example
 * // Returns "2days : 6hrs : 10min : 30sec"
 * getRemainingTime(195030000);
 *
 * @example
 * // Returns "1hr : 5min : 45sec"
 * getRemainingTime(3945000);
 */
  export  function getRemainingTime(ms) {
    // Calculate time components
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Remaining time components after division
    const remainingSeconds = seconds % 60;
    const remainingMinutes = minutes % 60;
    const remainingHours = hours % 24;

    // Construct the formatted string
    let formattedTime = "";

    if (days > 0) {
      formattedTime += `${days}day${days > 1 ? "s" : ""} : `;
    }
    if (remainingHours > 0) {
      formattedTime += `${remainingHours}hr${remainingHours > 1 ? "s" : ""} : `;
    }
    if (remainingMinutes > 0) {
      formattedTime += `${remainingMinutes}min : `;
    }
    formattedTime += `${remainingSeconds}sec`;

    return formattedTime;
  }
