import React from "react";

const JudgeDebateViewBoxUi = ({ team }) => {
  return (
    <div className="shadow-md flex-1 h-[300px] rounded-md bg-gray-50 p-2 flex gap-3 flex-col">
      <div className="w-full">
        <div>{team.name}</div>
      </div>
        <div className="flex gap-2 h-full flex-wrap">
          {team.members.map((member, index) => (
            <div key={index} className={`flex flex-1 bg-slate-200 rounded-md w-full h-full items-center content-center justify-center flex-col ` }>
              <img
                referrerPolicy="no-referrer"
                className="w-20 h-20 rounded-full  border border-blue-600"
                src={member.avatar}
                alt="userImg"
              />
              <p className="text-md text-black"> {member.firstName} {member.lastName} </p>
            </div>
          ))}
        </div>
    </div>
  );
};

export default JudgeDebateViewBoxUi;
