import { JudgeDebateReducerEnum } from "./enum";
import { getRemainingTime } from "./methods";

class JudgeDebateService {
  constructor({ debateState, debate, dispatch, intervalRef }) {
    this.debateState = debateState;
    this.debate = debate;
    this.dispatch = dispatch;
    this.intervalRef = intervalRef;
  }
  setupDebateState() {
    const { state } = this.debate;

    if (this.debateState.hasStated && state) {
      this.dispatch({
        type: JudgeDebateReducerEnum.UPDATE_DEBATE_STATE,
        payload: state,
      });
    }
    if (!this.debateState.hasStated) {
      if (this.debateState.isLive) {
      }
    }
  }

  /**
   * startDebate is used to start the judge debate for a motion
   * @returns {void}
   */
  startDebate() {
    if (this.debateState.isLive) {
      const completed_motions = this.debateState.completed_motions;
      const current_motion = completed_motions + 1;
      const { timeFormat } = this.debate.tournament_motions[completed_motions];
      if (!timeFormat) return;
      const { newTimeFormat, endAt } = this.createEndTimeFormat(timeFormat);
      const { time, endTime, team, count } = newTimeFormat[0];

      let isInterval = team === "break";
      let debateStatePayload = {
        endAt: endTime,
        isInterval,
        startedAt: Date.now(),
        speakTeam: team,
        speakTime: time,
        hasStarted: true,
        hasFinished: false,
        round_shot: count,
        changedAt: Date.now(),
        noOfRounds: timeFormat.length,
        both: team === "both",
        timeFormat,
        current_motion,
        completed_motions,
      };
      this.dispatch({
        type: JudgeDebateReducerEnum.UPDATE_DEBATE_STATE,
        payload: debateStatePayload,
      });
      this.handleTimer(debateStatePayload);
    }
  }
  /**
   *
   * @param {*} debateState
   * HandlerTimer is used to update the time left for the debate round
   */
  handleTimer(debateState) {
    if (debateState) {
      let { endAt: end } = debateState;
      let diffs = end - Date.now();
      if (diffs >= 0) {
        this.dispatch({
          type: JudgeDebateReducerEnum.SET_ROUND_REMAINING_TIME,
          payload: getRemainingTime(diffs),
        });
      }
      let interval = setInterval(() => {
        const { endAt } = debateState;
        const diff = endAt - Date.now();
        if (diff >= 0) {
          console.log("inside interval");
          this.dispatch({
            type: JudgeDebateReducerEnum.SET_ROUND_REMAINING_TIME,
            payload: getRemainingTime(diff),
          });
        } else {
          // this.intervalRef.current.forEach((int) => {
          //   clearInterval(int);
          // });
          // this.intervalRef.current = [];
          // this.handleFinishRound();
        }
      }, 1000);

      // this.intervalRef.current.push(interval);
    }
  }

  getCurrentTimeFormat(timeFormat) {
    if (!timeFormat) return;
    let resultFormat;
    for (let i = 0; i < timeFormat.length; i++) {
      let currentFormat = timeFormat[i];
      let now = Date.now();
      if (i === 0 && currentFormat.endTime > now) {
        resultFormat = currentFormat;
        break;
      } else {
        if (i !== 0) {
          let prev = timeFormat[i - 1].endTime;
          let curr = timeFormat[i].endTime;
          if (now > prev && now < curr) {
            resultFormat = currentFormat;
            break;
          }
        }
      }
    }

    return resultFormat;
  }
  createEndTimeFormat(timeFormat) {
    let newTimeFormat = [...timeFormat];
    let endAt = newTimeFormat.reduce((time, format, i) => {
      let newTime;
      if (format.team === "break") {
        newTime = time + 10000;
        format.endTime = newTime;
        format.count = i + 1;
      } else {
        newTime = time + format.time * 60 * 1000;
        format.endTime = newTime;
        format.count = i + 1;
      }
      return newTime;
    }, Date.now());

    return { endAt, newTimeFormat };
  }
}

export default JudgeDebateService;

//
