import React from 'react'
import styles from "./YouthCoach.module.css"
import CoachItem from '../CoachItem/CoachItem'
const YouthCoach = () => {
  return (
    <div className={styles.youthCoachContainer}>
        <h1 className={styles.youthHeaderText}> Top Youth Coaches</h1>
        <div className={styles.youthCoachWrapper}>
          {
            youthCoach.map(youth=><CoachItem format={true} data={youth} key={youth.name}/>)
          }
        </div>
    </div>
  )
}

export default YouthCoach
const youthCoach =[

  {
    name:"Amanda",
    desc:`Amanda G8，系统学习英辨两年，目前Lv5。|
前期赛绩：|
2023.08.13纽约大学BP辩论赛8强，Varsity semi final。48支队伍，排名第五。|
2023 Apr.NHSDLC春季赛初中组16强 最佳辩手 | 
2023,Mar.外研社第二届粤港澳大湾区大学生BP赛菁英组最佳辩手 |
2023 Feb.WSDA冬季全国冠军赛 初中组亚军 优秀辩手 |
2022 Dec.WSDA秋季赛 初中组 季军 最佳辩手 |
2022 Nov..WSDA秋季初中组亚军 优秀辩手 |
2022,Aug.WSDA夏季邀请赛 优秀辩手 |

Amanda G8,  2 years of systematic learning in English discrimination, currently in BP Lv5. |
Preliminary performance:
 -August 13, 2023, the New York University BP Debate Competition, the Varsity Semifinal. ranking Fifth. |
 -2023 Apr. NHSDLC Spring Competition Junior High School Group Top 16&Best Debeater |
 -2023, Mar. Elite Group of the Second Guangdong Hong Kong Macao Greater Bay Area College Student BP Competition,Best Debeater |
 -2023 Feb.WSDA Winter National MS,Runner up & Outstanding speaker |
 -2022 Dec.WSDA Autumn Tournament MS,Second Runner up&Best Debater |
 -2022 Nov. WSDA Autumn Junior High School Group ,Runner up &Outstanding Debater 
 -2022 August WSDA Summer Invitational Outstanding Debater`,
 image:"/images/amanda.jpeg",
 country:"China",
 calendlyLink:"https://calendly.com/lijialin1117/debai-coach"
  },
    {
    name:"Julia",
    desc:`Julia，G7 |
目前就读于Astra Nova |
部分获奖情况： |
Junior 即兴辩论： |
2023.5 WSDA 大湾区 冠军 |
2023.8 WSDA 全国总决赛 一等奖 |
WSD辩论： |
2023.5 NHSDLC 线上赛 亚军 |
2023.8 NHSDLC 线上全国邀请赛 冠军&最佳辩手 |
PF辩论： |
2023.4 NHSDLC 初中新手组 季军&优秀辩手 |
2023.6 WSDA 北京地区初中组 冠军&优秀辩手 |
2023.8 WSDA 全国总决赛 季军&优秀辩手  |
演讲比赛： |
2023.5 WSDA 大湾区自由演讲4-6级组 一等奖 |
2023.6 TOC 原创演讲 亚军 |
2023.7 TOC 自由演讲 亚军 |
2023.8 希望之星 小高组 全国十强 |
Julia ，G7 |
Currently studying at Astra Nova |
Partial Awards: |
Junior debate: |
2023.5 WSDA Great Bay regional champion ||
2023.8 WSDA national round 1st prize 
WSD: |
2023.5 NHSDLC regional online runner up |
2023.8 NHSDLC national round online champion & best speaker |
PF:  |
2023.4 NHSDLC MS Novice semifinalist & outstanding speaker|
2023.6 WSDA MS Beijing regional champion & outstanding speaker |
2023.8 WSDA MS national round semifinalist & outstanding speaker  |    
Speech: |
2023.5 WSDA expository 4-6 grade Great Bay regional 1st prize |
2023.6 TOC OO runner up |
2023.7 TOC expository runner up |
2023.8 Star of Outlook national top 10`,
 image:"/images/julia.jpeg",
 country:"China",
 calendlyLink:"https://calendly.com/houserethen093/debai-coach"
  },
  {
    name:"Cecilia",
    image:"/images/Cecilia.jpeg",
    desc:`Cecilia G8 |
    Crazy in love with debating.|
   2023 Got interviewed by National High School League of China in July 2023 |
   4 years of debate experience: |
   2023.8.4 NHSDLC National Offline Invitational Tournament: Top 16 in the Open division; 7th best debater; |
   2023.5.27 NHSDLC Spring online event: Open division Quarter Finalist |
   2023.4.29 NHSDLC Spring online event: Open division Finalist 2nd Best Speaker |
   2023.4.8 NHSDLC Spring online event: Open division Semifinalist |
   2023.3.25 NHSDLC Spring online event: Open division Semifinalist |
  2022.10.29: National High School Debate League of China High School Semifinalist and 3rd best speaker |
   2022.5.28: National High School Debate League of China Middle School Division 4th best Speaker Award |
   2021.7.19: Qualified for Worldsss Scholars Cup’s final round in Yale. 6th best Individual debate speaker. Best Showcase Award.  Placed 3rd in the entire division. |
   2021: National High School Debate League of China Middle School Division 7th Speaker Award |
   2021: National High School Debate League of China Middle School Division 6th Speaker Award |
    2020: Qualified in National High School Debate League of China Middle School Grand Final and Placed the 2nd Speaker |
   2019.4.29: The National High School Debate League of China Finalists . `,
   country:"China",
   calendlyLink:"https://calendly.com/ccsklu1/debate-meeting-on-debai-org",
  }

]

