import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const TeamFormatTable = ({ debateForm, handleSpeakTimeChange }) => {
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="table_heading_form">Speech Round</Th>
            <Th className="table_heading_form">Speech Team</Th>
            <Th className="table_heading_form" isNumeric>
              Speech Time{" "}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {debateForm.timeFormat.map((format, index) =>
            format.team !== "break" ? (
              <Tr key={index}>
                <Td color={"gray.700"}>{format.round}</Td>
                <Td fontSize={"13px"} letterSpacing={"1px"} color={"gray.700"}>
                  {format.team}
                </Td>
                <Td padding={"8px"} textAlign={"end"}>
                  <input
                    className="speak_time_input"
                    onChange={(e) => handleSpeakTimeChange(e, index)}
                    type="text"
                    name=""
                    value={format?.time}
                    id=""
                  />
                </Td>
              </Tr>
            ) : (
              ""
            )
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TeamFormatTable;
