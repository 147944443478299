import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import SelectedParticipants from "../../Participants/selectedParticipants/SelectedParticipants";
import { GrClose } from "react-icons/gr";
import { BsMicrosoftTeams, BsPersonAdd } from "react-icons/bs";
import "./TeamForm.css";

import SearachUserPopover from "../../../Layouts/popovers/selectParticipants/SelectParticipants";
import { debateFormat } from "../../../utils/data";

const TeamForm = ({ team, index, setDebateForm, debateForm }) => {
  // console.log("team",teamNameError,setTeamNameError)

  const toast = useToast();
  const [participantsSearchInput, setParticipantsSearchInput] = useState("");

  // console.log(teamNameError)

  // console.log(teamNameError)

  const handleSelectParticipants = (user, teamIndex) => {
    // setDebateForm

    const valid = handleTeamParticipantsValidation(user, teamIndex);

    if (!valid) return;

    setDebateForm((prev) => ({
      ...prev,
      teams: prev.teams.map((team, index) => {
        if (index === teamIndex) {
          return { ...team, members: [...team.members, user] };
        } else {
          return team;
        }
      }),
    }));
  };

  const removeSelectedParticipants = (user, teamIndex) => {
    let debateTeam = debateForm.teams;
    //  let filteredDebateTeam= debateTeam.map(team=>  team.members.filter(teamMember=>teamMember._id !== user._id))
    let filteredTeam = debateTeam.map((team) => ({
      ...team,
      members: team.members.filter((teamMember) => teamMember._id !== user._id),
    }));
    setDebateForm((prev) => ({
      ...prev,
      teams: filteredTeam,
    }));
  };

  const handleTeamParticipantsValidation = (user, index) => {
    let error = "";
    let max_team_members = debateFormat.get(debateForm.type);
    if (debateForm.teams[index]?.members?.length >= max_team_members[0]) {
      error = `${debateForm.type} can't have more than ${max_team_members} members in each team`;
    }

    // if (debateForm.type === "Lincoln–Douglas") {
    //   full = debateForm.teams[index].members.length >= 1;
    //   error = "Lincoln-Douglas can have only one participant in a team";
    // } else if (debateForm.type === "British Parliamentary") {
    //   full =
    //     debateForm.teams[index].members.length >=
    //     parseInt(debateForm.team_format);

    //   if (debateForm.team_format === "2") {
    //     error = `participants limit exceeded change team format to 4 vs 4`;
    //   } else {
    //     error = `Maximum partcipants exceeded`;
    //   }
    // } else {
    //   full = debateForm.teams[index].members.length >= 4;
    //   error = "Team can have only 4 participants";
    // }

    if (error) {
      toast({
        description: error,
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
      return false;
    }

    // if the user already exist
    if (isUserInTeam(user)) return false;

    return true;
  };

  const isUserInTeam = (user) => {
    let debateTeams = debateForm.teams;
    const exist = debateTeams.filter((team) =>
      team.members.find((teamMember) => teamMember._id === user._id)
    );
    if (exist.length > 0) {
      toast({
        description: "This user is already in a team",
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
      return true;
    }
    return false;
  };

  return (
    <div className="teamFormWrapper" pinkBg={index === 1 ? true : false}>
      <div className="team_form_input_box">
        <div className="input_item">
          <BsMicrosoftTeams />
          <input
            type="text"
            autoComplete="off"
            className="search_team_name_input"
            placeholder={` ${index === 0 ? "First" : "Second"} teamname`}
            name="name"
            value={team.name}
          />
        </div>
        <form className="search_user_input input_item">
          <BsPersonAdd />
          <input
            type="text"
            autoComplete="off"
            placeholder="Add member..."
            className="addMember_input"
            onChange={(e) => setParticipantsSearchInput(e.target.value)}
            value={participantsSearchInput}
          />
          {participantsSearchInput.length > 0 && (
            <GrClose
              className={"searchUserSvgClose"}
              onClick={() => setParticipantsSearchInput("")}
            />
          )}
        </form>
      </div>
      <SelectedParticipants
        index={index}
        removeSelectedParticipants={removeSelectedParticipants}
        selectedParticipants={team.members}
      />
      {participantsSearchInput.length > 0 && (
        <SearachUserPopover
          team={team}
          index={index}
          handleSelectParticipants={handleSelectParticipants}
          selectedParticipants={team.members}
          participantsSearchInput={participantsSearchInput}
        >
          <div></div>
        </SearachUserPopover>
      )}
    </div>
  );
};

export default TeamForm;
