import React from "react";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";

const JudgeDebateActionButtonsUi = ({
  handleOpenMic,
  handleCloseMic,
  hasStarted,
  isLive,
  isMicMuted,
  handleStartDebate,
}) => {
  return (
    <div className="flex items-center w-full justify-center gap-x-3">
      {isLive && !hasStarted ? (
        <button
          className="bg-blue-600 h-[45px] rounded-sm  text-white px-3"
          onClick={handleStartDebate}
        >
          START DEBATE
        </button>
      ) : null}

      {hasStarted ? (
        isMicMuted ? (
          <BsFillMicFill className="cursor-pointer" onClick={handleOpenMic} />
        ) : (
          <BsFillMicMuteFill
            className="cursor-pointer"
            onClick={handleCloseMic}
          />
        )
      ) : null}

      <button className="bg-red-500 h-[45px] rounded-sm  text-white px-3">
        LEAVE
      </button>
    </div>
  );
};

export default JudgeDebateActionButtonsUi;
