import styles from "./MysteryBox.module.css";
import { BiArrowFromLeft, BiArrowToLeft } from "react-icons/bi";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { getNameAndVoteOfTeams } from "../../../utils/services";
import { Enums } from "../../../redux/action/actionTypes/Enumss";
import { useNavigate } from "react-router-dom";
import DebateTranscriptModal from "../../../Layouts/modal/DebateFinishedModal/DebateTranscriptModal";
import { MdHowToVote } from "react-icons/md";
import { debateFormatEnum } from "../../../utils/enum";

const MysteryBox = ({  debateResult, activeDebate, isAudience }) => {
  const [transcriptText, setTranscriptText] = useState([]);

  useEffect(() => {
    if (activeDebate?.transcript) {
      // writeMessage({text:activeDebate.transcript,_id:Date.now()})
      let text = activeDebate?.transcript;
      setTranscriptText(text.split("\n"));
    }
  }, [activeDebate]);

  const [bothTeams, setBothTeams] = useState([]);
  const [judgeType, setJudgeType] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (!activeDebate) return;
    const { judgeType: type } = activeDebate;
    setJudgeType(type);
    setBothTeams(getNameAndVoteOfTeams(activeDebate.teams));
  }, [activeDebate]);

  const positionMap = {
    1: "First",
    2: "Second",
    3: "Third",
    4: "Fourth",
  };

  const getResultText = () => {
    console.log("debate result",debateResult)
    
    if (!activeDebate || !debateResult) return;
    let resultText = "";

    switch (debateResult) {

      case Enums.NOJUDGE:
        resultText = "This is a No Judge debate .Well done!";
        break;
      case Enums.WON:
        resultText = "Your team won the debate.";
        break;

      case Enums.LOSE:
        resultText = "Your team lose the debate.";
        break;

      case Enums.TIED:
        resultText = "DEBATE TIED";
        break;

      case Enums.INVALID:
        resultText =
          "Oops ,something is wrong with this debate , please create a new one and try again.";
        break;

      case "1":
        resultText = "Your team won the first place.";
        break;

      case "2":
        resultText = "Your team won the second place.";

        break;

      case "3":
        resultText = "Your team won the third place.";
        break;

      case "4":
        resultText = "Your team won the fourth place.";
        break;

      case Enums.AUDIENCE:
        resultText = `${activeDebate?.winner} won the debate.`;
        break;
     
      default:
        resultText = `Failed to decide winner.`;
        break;
    }
    return resultText;
  };
  console.log(
    "test",
    activeDebate?.type === debateFormatEnum["British Parliamentary"],
    activeDebate?.results
  );
  return (
    <>
      <motion.div
        initial={{
          x: "-100px",
          scale: 0.9,
          opacity: 0.5,
        }}
        animate={{
          x: "0px",
          scale: 1,
          opacity: 1,
        }}
        transition={{ duration: 0.5, bounce: 200 }}
        className={styles.modelContent}
      >
        <div className={styles.top_winning_team}>
          {debateResult !== Enums.INVALID && (
            <img
              className={styles.win_logo}
              width="40"
              height="40"
              src="https://img.icons8.com/external-smashingstocks-flat-smashing-stocks/66/external-Win-casino-smashingstocks-flat-smashing-stocks.png"
              alt="external-Win-casino-smashingstocks-flat-smashing-stocks"
            />
          )}

          <h1 className={styles.winning_team_text}> {getResultText()}</h1>
        </div>
        <h1 className={styles.debate_topic}>💥{activeDebate?.topic}</h1>
        <div className={styles.vote_count_box}>
          {activeDebate?.judgeType === Enums.VOTING &&
            bothTeams.map((team, index) => (
              <>
                <div key={index} className={`${styles.vote_button_count}`}>
                  <MdHowToVote />
                  <p>
                    {team.vote}&nbsp;
                    {team.name}
                  </p>
                </div>
              </>
            ))}
        </div>
        {activeDebate?.type === debateFormatEnum["British Parliamentary"] &&
          activeDebate?.results &&
          debateResult !== Enums.INVALID &&
          debateResult !== Enums.NOJUDGE && (
            <div className="flex justify-between gap-3">
              <div className="flex flex-col gap-y-3">
                {Object.keys(activeDebate?.results)
                  .slice(0, 2)
                  .map((key) => {
                    return (
                      <div className="shadow-md rounded-md cursor-pointer flex items-center p-2 bg-[#7c38f9]">
                        <h1 className="font-medium uppercase text-[14px] tracking-wide">
                          {`${positionMap[key.toString()]} Place :   ${
                            activeDebate?.results[key]
                          }`}
                        </h1>
                      </div>
                    );
                  })}
              </div>
              <div className="flex flex-col gap-y-3">
                {Object.keys(activeDebate?.results)
                  .slice(2, 4)
                  .map((key) => {
                    return (
                      <div className="shadow-md  cursor-pointer p-2 flex items-center rounded-md bg-[#7c38f9]">
                        <h1 className="font-medium uppercase text-[14px] tracking-wide">
                          {positionMap[key.toString()]} Place :{" "}
                          {activeDebate?.results[key]}
                        </h1>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

        {/* { !isAudience && (
          <ClaimReward
            transcript={transcriptText}
            handleNext={handleNext}
            goToNext={goToNext}
            judgeType={judgeType}
            activeDebate={activeDebate}
            setGoToNext={setGoToNext}
            debateResult={debateResult}
          />
        )} */}

          <>
            <div className={styles.mysteryButtonBox}>
              <button
                className={styles.nextButton}
                onClick={() => navigate("/")}
              >
                <p className="text-[14px]">Home</p>
                <BiArrowToLeft className={styles.backIcon} />
              </button>
              {judgeType === Enums.AIJUDGE &&
                debateResult !== Enums.INVALID && (
                  <DebateTranscriptModal transcript={transcriptText}>
                    <button className={styles.nextButton}>
                      <BiArrowFromLeft color="white" fill="white" />
                      Result
                    </button>
                  </DebateTranscriptModal>
                )}
            </div>
          </>
      </motion.div>
    </>
  );
};
export default MysteryBox;
