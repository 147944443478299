import {
    Popover,
    PopoverTrigger,
    PopoverContent,
  } from '@chakra-ui/react'
  import React, { useState } from 'react'
import styles from "./SearchuserPopover.module.css"
import SearchedParticipantsList from '../../../components/Participants/SearchedParticipantsList/SearchedParticipantsList'



  const SearachUserPopover= ({children ,team,index, handleSelectParticipants, selectedParticipants, participantsSearchInput }) => {

    const [isOpen,setisOpen] = useState(true)

      const handleClosePop=()=>{
        setisOpen(false)
      }
  

  return (
    <>
    <Popover autoFocus={false} isOpen={isOpen} placement="end">

    <PopoverTrigger>
        {children}
    </PopoverTrigger>
  
    <PopoverContent border={"none"} borderRadius={"10px"}>
      <div className={styles.searchUserBox}>
        <div className={styles.searchUserContainer}>
           <SearchedParticipantsList searchUser={true} handleClosePop={handleClosePop} team={team} index={index} handleSelectParticipants={handleSelectParticipants} selectedParticipants={team?.members} participantsSearchInput={participantsSearchInput}/>
        </div>
      </div>
    </PopoverContent>
  </Popover>
    </>
  )
}

export default SearachUserPopover