import React, { useEffect, useReducer } from "react";
import JudgeDebateView from "./JudgeDebateView";
import { getJudgeDebateById } from "../../utils/Api";
import { useParams } from "react-router-dom";
import { JudgeDebateReducerEnum } from "../../utils/enum";
import { getTimeFromMs } from "../../utils/services";
import JudgeDebateService from "../../utils/JudgeDebateService";
const initiateSate = {
  debate: null,
  motions: [],
  isMicMuted: false,
  audioTracks: {
    localAudioTracks: null,
    remoteAudioTracks: {},
  },
  startTimeCountDown: {
    day: null,
    hour: null,
    min: null,
    sec: null,
  },
  speechTimeLeft: {
    min: null,
    sec: null,
  },
  debateState: {
    round_remaining_time: "",
    isLive: null,
    endAt: null,
    intervalRef: false,
    startedAt: null,
    speakTeam: null,
    speakTime: null,
    hasStarted: false,
    hasFinished: false,
    round_shot: null,
    changedAt: null,
    noOfRounds: null,
    both: null,
    timeFormat: null,
    isInterval: false,
    current_motion: 0,
    completed_motions: 0,
  },
  users: {
    activeSpeakers: [],
    RoomMembers: [],
  },
};

function JudgeDebateRedcuer(state, action) {
  switch (action.type) {
    case JudgeDebateReducerEnum.ADD_JUDGE_DEBATE_DETAILS:
      return { ...state, debate: action.payload };
    case JudgeDebateReducerEnum.UPDATE_START_TIME_COUNT_DOWN:
      return {
        ...state,
        startTimeCountDown: {
          day: action.payload.day,
          hour: action.payload.hour,
          min: action.payload.min,
          sec: action.payload.sec,
        },
      };
    case JudgeDebateReducerEnum.UPDATE_DEBATE_STATE:
      return {
        ...state,
        debateState: { ...state.debateState, ...action.payload },
      };
    case JudgeDebateReducerEnum.OPEN_MIC:
      return {
        ...state,
        isMicMuted: false,
      };
    case JudgeDebateReducerEnum.CLOSE_MIC:
      return {
        ...state,
        isMicMuted: true,
      };

    case JudgeDebateReducerEnum.SET_ROUND_REMAINING_TIME:
      return {
        ...state,
        debateState: {
          ...state.debateState,
          round_remaining_time: action.payload,
        },
      };
    default:
      return state;
  }
}

const JudgeDebateLogic = () => {
  const [state, dispatch] = useReducer(JudgeDebateRedcuer, initiateSate);
  const startsIntervalIdRef = React.useRef(null);
  let { id } = useParams();
  const JudgeDebate = new JudgeDebateService({
    debateState: state.debateState,
    debate: state.debate,
    dispatch,
  });
  useEffect(() => {
    const fetchJudgeDebateById = async () => {
      try {
        const { data } = await getJudgeDebateById(id);
        dispatch({
          type: JudgeDebateReducerEnum.ADD_JUDGE_DEBATE_DETAILS,
          payload: data.message,
        });
      } catch (error) {}
    };
    fetchJudgeDebateById();
  }, [id]);
  useEffect(() => {
    if (state.debateState.hasStarted === false) {
      if (state.debate) {
        let startDate = state.debate.start_date;
        let startDateInMs = new Date(startDate).getTime();
        startsIntervalIdRef.current = setInterval(() => {
          if (startDateInMs > Date.now()) {
            const { sec, min, hour, day } = getTimeFromMs(startDateInMs);
            dispatch({
              type: JudgeDebateReducerEnum.UPDATE_START_TIME_COUNT_DOWN,
              payload: {
                day,
                hour,
                min,
                sec,
              },
            });
          } else {
            dispatch({
              type: JudgeDebateReducerEnum.UPDATE_DEBATE_STATE,
              payload: {
                isLive: true,
              },
            });
            clearInterval(startsIntervalIdRef.current);
          }
        }, 1000);
      }
    }
  }, [state.debate, state.debateState.hasStarted]);
  console.log(state);
  return (
    <JudgeDebateView
      debate={state.debate}
      debateState={state.debateState}
      startTimeCountDown={state.startTimeCountDown}
      dispatch={dispatch}
      isMicMuted={state.isMicMuted}
      JudgeDebate={JudgeDebate}
    />
  );
};

export default JudgeDebateLogic;
