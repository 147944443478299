import "./Navbar.css";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SignOutPopover from "../popovers/SignoutPopovers";
import NavPoper from "../Skeleton/NavPoper/NavPoper";
import { FcMenu } from "react-icons/fc";

const Navbar = () => {
  const { data } = useSelector((state) => state.user);
  const location = useLocation().pathname;
  const adminPage = location.split("/")[1];

  // console.log(data)

  return (
    <div className="NavbarWrapper">
      <Link to={"/"}>
        <div className="nav_logo_box">
          <img className="navLogo" src="/images/logo.jpg" alt="logoImg" />
        </div>
      </Link>
      <div className="dropDownBox">
        <NavPoper>
          <FcMenu />
        </NavPoper>
      </div>
      <div className={"nav_right"}>
        <ul className="nav_list">
          {adminPage !== "admin" && (
            <>
              <Link to={"/"}>
                <li className={`${location === "/" && "active_li"}`}>Home</li>
              </Link>

              {/* <Link to={"/chatbot"}>
          <li className={`${location === "/chatbot" && "active_li"}`}>chatbot </li>
          </Link>  
         */}

              <Link to={adminPage === "admin" ? "motion" : "/motion"}>
                <li className={`${location === "/motion" && "active_li"}`}>
                  motion{" "}
                </li>
              </Link>
              <Link to={"/subscription"}>
                <li
                  className={`${location === "/subscription" && "active_li"}`}
                >
                  Subscription{" "}
                </li>
              </Link>
              {data && (
                <Link to={`/profile/${data?._id}`}>
                  <li className={`${location === "/profile" && "active_li"}`}>
                    Profile
                  </li>
                </Link>
              )}
            </>
          )}
          {adminPage === "admin" && (
            <>
              <Link to={adminPage === "admin" ? "motion" : "/motion"}>
                <li className={`${location === "/" && "active_li"}`}>
                 Debate Tournament
                </li>
              </Link>
              <Link to={adminPage === "admin" ? "motion" : "/motion"}>
                <li className={`${location === "/motion" && "active_li"}`}>
                  motion{" "}
                </li>
              </Link>
              <Link to={`maintainance`}>
                <li className={`${location === "/profile" && "active_li"}`}>
                  Maintainance
                </li>
              </Link>
            </>
          )}
        </ul>
        {data ? (
          <SignOutPopover
            children={
              <>
                <div className="loggedInUserBox">
                  <img
                    referrerPolicy="no-referrer"
                    width={"50px"}
                    src={data?.avatar}
                    alt="UserAvatar"
                  />
                  <div className="loggedInuserDetails">
                    <p>{data.firstName}</p>
                    <p>{data.email}</p>
                  </div>
                </div>
              </>
            }
          />
        ) : (
          <>
            <>
              <div className={"other_button_box"}>
                <Link to={"/login"}>
                  <button>Log in</button>
                </Link>
                {/* <Link to={"/signup"}>
                  <button className="signup_btn">Sign up</button>
                </Link> */}
              </div>
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
