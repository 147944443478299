import React, { useEffect, useRef, useState } from 'react'
import styles from "./WeeklyDebate.module.css"
import { updateWebsiteInfoApi } from '../../../utils/Api'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../redux/store'
import { useDispatch } from 'react-redux'

const WeeklyDebate = ({info}) => {

    const [defaultWeeklyDebateText,setDefaultWeeklyDebateText]   = useState()
    const debateInputRef = useRef();
    const [updateMode,setUpdateMode]= useState(false)
    const dispatch=useDispatch()
    const {SetRefreshNow} = bindActionCreators(actionCreators,dispatch )

    useEffect(()=>{
        setDefaultWeeklyDebateText(info?.weeklyDebate)
    },[info])


    const handleSubmit=async()=>{

      const debate =   debateInputRef.current.value;

      const payload = {
        weeklyDebate:debate
      }


        try {
            const { status} = await updateWebsiteInfoApi(payload)  
            if(status==200){
                SetRefreshNow()
                setUpdateMode(false)
            }
            } catch (error) {
            console.log(error)
        }
    }
    
    
  return (
    <div className={styles.weeklyDebateBox} >


    <div className={styles.weeklyHeader}> 


        <h1 className={styles.weeklyDebateHeader}>Weekly debate </h1>
        <button onClick={()=>setUpdateMode(!updateMode)}>{updateMode ? "Close":"Update"}</button>
    </div>
        <div className={styles.weeklyDebateText}>{info?.weeklyDebate}</div>

        <div className={`${styles.updateBox} ${updateMode ? styles.updateMode :""}`}>
            
        <textarea  ref={debateInputRef} defaultValue={defaultWeeklyDebateText} ></textarea>
        <button  className={styles.updateButton} onClick={handleSubmit}> Update Debate </button>
        </div>

    </div>
  )
}

export default WeeklyDebate