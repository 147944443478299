import { useDispatch, useSelector } from "react-redux";
import "./ProfileCard.css";
import { BsFillCalendarFill } from "react-icons/bs";
import {
  getUsersDebateCountApi,
  logoutApi,
  updateUserapi,
} from "../../../utils/Api";
import { FaUserEdit } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { bindActionCreators } from "redux";
import { useToast } from "@chakra-ui/react";
import { actionCreators } from "../../../redux/store";
import { useEffect, useRef, useState } from "react";
import { getFlag } from "../../../utils/services";
import useUploadFile from "../../../hook/useUploadFile";
const ProfileCard = ({ userData }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { RemoveLoggedInUser, SetRefreshNow } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const profile_input_ref = useRef();
  const { data } = useSelector((state) => state.user);

  const [flag, setFlag] = useState("");
  const [uploadingImage, setUploadingImage] = useState(false);

  const { uploadFile } = useUploadFile();
  const [debatesCount, setDebatesCount] = useState({
    upcomingDebate: null,
    liveDebate: null,
    allDebates: null,
  });
  const [isMe, setIsMe] = useState(null);
  const [userAvatar, setUserAvatar] = useState([]);

  useEffect(() => {
    if (!userData) return;
    let newAvatars = [];
    userData.equipedAvatars.forEach((a) => {
      if (newAvatars.some((avt) => avt.avatar === a.avatar)) {
      } else {
        newAvatars.push(a);
      }
    });
    setUserAvatar(newAvatars);
  }, [userData]);

  const handleLogout = async () => {
    try {
      const res = await logoutApi();
      if (res.status === 200) {
        RemoveLoggedInUser();
        toast({
          title: "",
          description: "You Logged out successfully",
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      } else {
        throw Error("something went wrong");
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  // const handleUpdateAvatar = async (name, avatar) => {
  //   if (!data) return;
  //   try {
  //     const res = await updateUserapi(userData._id, { avatar });
  //     toast({
  //       title: "",
  //       description: "Avatar changed successfully",
  //       status: "success",
  //       duration: 5000,
  //       position: "top",
  //       isClosable: true,
  //     });
  //     SetRefreshNow();
  //   } catch (error) {
  //     toast({
  //       title: "",
  //       description: "Failed to change avatar",
  //       status: "error",
  //       duration: 5000,
  //       position: "top",
  //       isClosable: true,
  //     });
  //   }
  // };

  const handleGetDebatesCount = async () => {
    try {
      const res = await getUsersDebateCountApi(userData?._id);
      console.log(res);
      if (res.status === 200) {
        const { liveDebate, upcomingDebate, allDebates } = res.data.message;
        setDebatesCount({
          liveDebate,
          upcomingDebate,
          allDebates,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSubscriptionPlan = () => {
    if (!data) return;
    const { subscription } = data;
    if (subscription?.active) {
      return subscription?.plan;
    } else if (
      !subscription?.status ||
      subscription?.status === "canceled" ||
      !subscription
    ) {
      return "Free";
    }
  };

  useEffect(() => {
    getFlag(userData?.country).then((flag) => {
      setFlag(flag);
    });
    if (!userData?._id) return;
    if (userData._id === data?._id) {
      setIsMe(true);
    } else {
      setIsMe(false);
    }
    handleGetDebatesCount();
  }, [data, userData]);

  const handleFileChange = async (e) => {
    try {
      if (e.target.files && e.target.files[0]) {
        setUploadingImage(true);
        const image_url = await uploadFile(e.target.files[0]);
        await updateUserapi(userData._id, { avatar: image_url });
        SetRefreshNow();
        toast({
          title: "",
          description: "Profile picture changed successfully",
          status: "success",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      }
      setUploadingImage(false);
    } catch (error) {
      setUploadingImage(false);
    }
  };

  return (
    <div className="profileCardContainer">
      <div className="ProfileCard">
        <div className="profile_card_top">
          <div className="profile_card_left">
            <div className="profile_basic_info_item">
              <div className="profile_card_avatar_wrapper">
                <img
                  draggable={false}
                  src={userData?.avatar}
                  alt={`${userData.firstname}'s avatar`}
                />
              </div>
              <div className="profile_card_item basic_userinfo_item">
                <div className="user_main_detail">
                  <div className="profile_name_user_point_box">
                    <h1 className="profile_card_username">
                      {`${userData?.firstName} ${userData?.lastName || ""}`}
                    </h1>

                    <div className="profile_card_user_points">
                      <p>{Math.trunc(userData?.points)}</p>
                      <img
                        width="94"
                        height="94"
                        src="https://img.icons8.com/3d-fluency/94/popular-topic.png"
                        alt="popular-topic"
                      />
                    </div>
                  </div>
                  <p className="profile_card_email">{userData?.email}</p>
                  <div className="profile_card_user_country_box">
                    <img width={"20px"} src={flag} alt="" />
                    <p>{userData?.country} </p>
                  </div>
                </div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={profile_input_ref}
                  onChange={(e) => handleFileChange(e)}
                />
                <div className="profile_action_button">
                  <button
                    onClick={() => profile_input_ref.current?.click()}
                    className="change_profile_image_button logout_button"
                  >
                    {uploadingImage ? (
                      <p>Uploading picture</p>
                    ) : (
                      <>
                        <FaUserEdit />
                        <p> Change profile Picture</p>
                      </>
                    )}
                  </button>
                  {isMe === null ? (
                    ""
                  ) : isMe ? (
                    <button onClick={handleLogout} className="logout_button">
                      <IoLogOutOutline width={25} />
                      <p>Log out</p>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="profile_card_item_other_item">
            {/* <h1 className="profile_card_item_title">OTHER INFORMATION</h1> */}

            <div className="other_info_text_item">
              <img
                width="94"
                height="94"
                src="https://img.icons8.com/3d-fluency/94/micro.png"
                alt="micro"
              />
              <p className="other_info_text">
                {" "}
                Created {debatesCount.allDebates} Debates
              </p>
            </div>
            <div className="other_info_text_item">
              <img
                width="94"
                height="94"
                src="https://img.icons8.com/3d-fluency/94/alarm-clock--v2.png"
                alt="alarm-clock--v2"
              />

              <p className="other_info_text">
                {" "}
                Upcoming {debatesCount.upcomingDebate} Debates
              </p>
            </div>

            {userData?.subscription !== null && data && (
              <>
                <div className="other_info_text_item">
                  <img
                    width="94"
                    height="94"
                    src="https://img.icons8.com/3d-fluency/94/best-seller.png"
                    alt="best-seller"
                  />

                  <p className="other_info_text">
                    {" "}
                    Plan: {getSubscriptionPlan()}
                  </p>
                </div>
                {userData?.subscription?.status === "active" && (
                  <div className="other_info_text_item">
                    <BsFillCalendarFill className="upcoming_icon" />
                    <p className="other_info_text">
                      Reamining Days: {userData?.subscription?.remainingDays}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* {
                isMe === null ? "" : isMe ? <AvatarCarousel currentAvatar={userData?.avatar} onChange={handleUpdateAvatar} /> : ""
                
            } */}
      </div>
    </div>
  );
};

export default ProfileCard;

//  <div>
//    {userData?.equipedAvatars?.length > 0 && (
//      <>
//        <h1 className="profile_card_item_title">Equipped Avatars</h1>

//        <div className="image-row">
//          {userAvatar?.map((item, index) => (
//            <img
//              className="circle-image"
//              width={"40px"}
//              src={item.avatar}
//              alt="avatar1"
//              onClick={() => handleUpdateAvatar(item.type, item.avatar)}
//            />
//          ))}
//        </div>
//      </>
//    )}
//  </div>;
