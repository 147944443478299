import { useState, useEffect, useRef } from "react";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import { IoMdMicrophone } from "react-icons/io";
import { TiArrowBackOutline } from "react-icons/ti";
import { useSelector } from "react-redux";
import { getMyTeam } from "../../../utils/services";
import { debateFormatEnum } from "../../../utils/enum";
import * as RecordRTC from "recordrtc";
import "./DebateAction.css";
import { useAudioStream } from "../../../redux/context/AudioStreamContext";
import { axiosInstance } from "../../../utils/axios";
import { Enums } from "../../../redux/action/actionTypes/Enumss";

const DebateAction = ({
  micMuted,
  MicElmRef,
  isUserParticipant,
  WatchType,
  debateState,
  RoomService,
  roomMembers,
  activeDebate,
}) => {
  const socket = useRef(null);
  const recorder = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState("");

  const { data } = useSelector((state) => state.user);
  const [canStartDebate, setCanStartDebate] = useState(false);
  const audioStream = useAudioStream();

  useEffect(() => {
    if (activeDebate?.current) {
      let speakerTeams = roomMembers
        .filter((speaker) => {
          return activeDebate?.current.teams.some((team) => {
            return team.members.some((member) => {
              return member._id === speaker.id;
            });
          });
        })
        .map((speaker) => {
          let team = activeDebate?.current.teams.find((team) => {
            return team.members.some((member) => {
              return member._id === speaker.id;
            });
          });
          return { id: speaker.id, teamName: team.name, ...speaker };
        })
        .reduce((acc, speaker) => {
          if (acc[speaker.teamName]) {
            acc[speaker.teamName].push(speaker);
          } else {
            acc[speaker.teamName] = [speaker];
          }
          return acc;
        }, {});

      const TeamArray = [
        {
          name: activeDebate?.current?.teams[0].name,
          members: [],
        },
        {
          name: activeDebate?.current.teams[1].name,
          members: [],
        },
      ];

      Object.keys(speakerTeams).forEach((team) => {
        TeamArray.forEach((teamObj) => {
          if (teamObj.name === team) {
            teamObj.members = speakerTeams[team];
          }
        });
      });
    }
  }, [activeDebate.current, roomMembers]);

  useEffect(() => {
    if (!data || !activeDebate?.current) return;
    let teamName = getMyTeam(activeDebate?.current.teams, data._id)?.name;
    if (
      activeDebate?.current?.type === debateFormatEnum["British Parliamentary"]
    ) {
      setCanStartDebate(teamName === "OG");
    } else {
      setCanStartDebate(teamName === "PRO");
    }
  }, [data, activeDebate?.current]);

  const handleStartMicToggle = async (auto) => {
    if (debateState.speakTeam !== "break" || auto === true) {
      await RoomService.handleMicTogggle();
      if (activeDebate.current?.judgeType === Enums.AIJUDGE) {
        await handleListenerToggle();
      }
    }
  };

  const handleStartDebate = async () => {
    await RoomService.startDebate();
  };

  const handleLeaveRoom = async () => {
    try {
      await RoomService.handleLeaveRoom();
    } catch (error) {
      console.log(error);
    }
  };

  const generateTranscript = async () => {
    const lang = activeDebate?.current?.lang || "en";
    try {
      if (!audioStream) {
        console.error("Audio stream not available.");
        return;
      }

      const { data } = await axiosInstance.get("/chat/assembly-token");

      if (data.error) {
        throw new Error("error while fetching assembly ai token");
      }

      const { token } = data;

      socket.current = new WebSocket(
        `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}&language_code=${lang}`
      );

      const texts = {}; // Define texts variable here

      socket.current.onmessage = (voicePrompt) => {
        let msg = "";
        const res = JSON.parse(voicePrompt.data);
        texts[res.audio_start] = res.text;
        const keys = Object.keys(texts);
        keys.sort((a, b) => a - b);
        for (const key of keys) {
          if (texts[key]) {
            msg += ` ${texts[key]}`;
            console.log(msg);
          }
        }
        console.log("transcript", msg);
        setTranscript(msg);
      };

      socket.current.onerror = (event) => {
        console.error(event);
        socket.current.close();
      };

      socket.current.onclose = (event) => {
        console.log(event);
        socket.current = null;
      };

      socket.current.onopen = () => {
        recorder.current = new RecordRTC(audioStream, {
          type: "audio",
          mimeType: "audio/webm;codecs=pcm",
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 250,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1,
          bufferSize: 4096,
          audioBitsPerSecond: 128000,
          ondataavailable: (blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64data = reader.result;
              if (
                socket.current &&
                socket.current.readyState === WebSocket.OPEN
              ) {
                socket.current.send(
                  JSON.stringify({
                    audio_data: base64data.split("base64,")[1],
                  })
                );
              }
            };
            reader.readAsDataURL(blob);
          },
        });
        recorder.current.startRecording();
      };

      setIsRecording(true);
    } catch (error) {
      setIsRecording(false);
      console.log("error", error);
    }
  };

  const endTranscription = async () => {
    try {
      if (socket.current) {
        socket.current.send(JSON.stringify({ terminate_session: true }));
        socket.current.close();
      }

      if (recorder.current) {
        recorder.current.stopRecording(() => {
          recorder.current = null;
        });
      }

      await RoomService.addSpeechToChannel(transcript || "");
      console.log("Added speech to channel");

      setTranscript("");
      setIsRecording(false);
    } catch (error) {
      console.error("Error ending transcription:", error);
    }
  };

  const handleListenerToggle = async () => {
    try {
      if (isRecording) {
        await endTranscription();
      } else {
        await generateTranscript();
      }
    } catch (error) {
      console.error("Error starting speech recognition:", error);
    }
  };

  useEffect(() => {
    if (!debateState) return;
    if (
      debateState.round_shot > 1 &&
      debateState.round_shot % 2 === 0 &&
      isRecording &&
      activeDebate?.current?.judgeType === Enums.AIJUDGE
    ) {
      handleFinishRound(true).then((data) => {
        console.log("round finished");
      });
    }
    console.log(
      "round shot",
      debateState.round_shot,
      debateState.name,
      debateState.round_shot % 2 === 0
    );
  }, [debateState.round_shot]);

  const handleFinishRound = async (auto) => {
    await handleStartMicToggle(auto);
  };

  console.log("transcript", isRecording, transcript);

  return (
    <div className="debateActionContainer">
      {isUserParticipant && WatchType === "PARTICIPANT" && (
        <>
          {!debateState.isStarted && canStartDebate && (
            <button className="pass_mic_button" onClick={handleStartDebate}>
              <IoMdMicrophone />
              START DEBATE
            </button>
          )}
          <div className="DebateActionWrapper">
            {debateState.isStarted ? (
              micMuted ? (
                <BsFillMicMuteFill
                  ref={MicElmRef}
                  onClick={handleStartMicToggle}
                />
              ) : (
                <BsFillMicFill
                  className="activeMic"
                  ref={MicElmRef}
                  onClick={handleStartMicToggle}
                />
              )
            ) : (
              ""
            )}
          </div>
        </>
      )}
      <button
        className="leaveBtn leave_for_watch_type"
        onClick={handleLeaveRoom}
      >
        <TiArrowBackOutline />
        LEAVE
      </button>
    </div>
  );
};

export default DebateAction;
