import React from "react";
import JudgeDebateViewBoxUi from "../JudgeDebateViewBox/JudgeDebateViewBoxUi";
import JudgeDebateActionButtonsLogic from "../JudgeDebateActionButtons/JudgeDebateActionButtonsLogic";

const JudgeDebateScreenUi = ({
  teams,
  dispatch,
  isMicMuted,
  debateState,
  JudgeDebate,
}) => {
  return (
    <div className="flex flex-col gap-y-3">
      <h1>{debateState.round_remaining_time}</h1>
      <div className="flex w-full gap-x-2">
        {teams.map((team, index) => (
          <JudgeDebateViewBoxUi team={team} key={index} />
        ))}
      </div>
      <JudgeDebateActionButtonsLogic
        JudgeDebate={JudgeDebate}
        dispatch={dispatch}
        isMicMuted={isMicMuted}
        hasStarted={debateState.hasStarted}
        debateState={debateState}
      />
    </div>
  );
};

export default JudgeDebateScreenUi;
