import React from "react";
import TournamentTable from "./TournamentTable";
import { TextInput } from "flowbite-react";

const TournamentAdminDashboard = () => {
  return (
    <div className="max-w-[1200px] mx-auto w-[95%]">
      <div>
        <TextInput
          id="email3"
          type="email"
          placeholder="name@flowbite.com"
          required
          helperText={
            <>
              Global filter : search tournament by judge name and description .
            </>
          }
        />
      </div>
      <TournamentTable />
    </div>
  );
};

export default TournamentAdminDashboard;
