import React from "react";
import { getTimeCountDown } from "../../../utils/services";

const JudgeDebateStartCountDownUi = ({ startTimeCountDown }) => {
    
  return (
    <div className="h-[100px]">
      <div className="flex items-center content-center justify-center"> 
        {Boolean(
          parseInt(
            getTimeCountDown(
              null,
              startTimeCountDown.day,
              startTimeCountDown.hour,
              startTimeCountDown.min,
              startTimeCountDown.sec
            )
          )
        ) ? (
          <h1 className="text-2xl font-semibold">
            {getTimeCountDown(
              null,
              startTimeCountDown.day,
              startTimeCountDown.hour,
              startTimeCountDown.min,
              startTimeCountDown.sec
            ) + ""}
          </h1>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default JudgeDebateStartCountDownUi;
