export const debateFormatEnum = {
  "British Parliamentary": "British Parliamentary",
  "Public forum": "Public forum",
  "Lincoln–Douglas": "Lincoln–Douglas",
  WSDC: "WSDC",
  "Fun Debate": "Fun Debate",
  
};


export const JudgeDebateReducerEnum = {
  ADD_JUDGE_DEBATE_DETAILS: "ADD_JUDGE_DEBATE_DETAILS",
  UPDATE_START_TIME_COUNT_DOWN: "UPDATE_START_TIME_COUNT_DOWN",
  UPDATE_DEBATE_STATE: "UPDATE_DEBATE_STATE",
  CLOSE_MIC: "CLOSE_MIC",
  OPEN_MIC: "OPEN_MIC",
  SET_ROUND_REMAINING_TIME:"SET_ROUND_REMAINING_TIME"
};