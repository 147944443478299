import { useState, useEffect } from "react";

const useIsIOS = () => {
  const [isIOS, setIsIOS] = useState(null);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIOSDevice =
      /iPad|iPhone|iPod|Macintosh/.test(userAgent) && "ontouchend" in document;
    setIsIOS(isIOSDevice);
  }, []);

  return isIOS;
};

export default useIsIOS;
