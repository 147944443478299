

import {
    Modal,
    ModalOverlay,
    ModalContent,

    ModalBody,

    useDisclosure,
    ModalHeader,
  } from '@chakra-ui/react'
  // import styles from "./DebateFinishModal.module.css"
// import { useEffect } from 'react';
import styles from "./TermsAndConditions.module.css"
import { debaiInfoApi } from '../../../utils/Api';
import { useEffect, useState } from 'react';


  function AnalyzeResultModal({ children   }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [info,setInfo] = useState({})
   
    useEffect(()=>{
      getAppInfo()
    },[])
    const getAppInfo=async()=>{
      try {
       const {data,status} = await debaiInfoApi()
       if(status===200){
        setInfo(data.message[0])
       }

      } catch (error) {
          console.log(error)
      }

    }
    return (
      <>
      <span onClick={onOpen}>
        {children}
      </span>
        <Modal isOpen={isOpen } onClose={onClose}   >

          <ModalOverlay />
          <ModalContent  width={"95%"} maxWidth={"800px"}>
            {/* <ModalCloseButton /> */}
            <ModalHeader display={"flex"} gap={"10px"} className={styles.termsAndConditionHeader}>
            <h1>Terms And Conditions</h1>
            </ModalHeader>
            <ModalBody   >   
            <div  className={styles.TermsAndConditions}>

        
        <div className={styles.terms_paragraphs}>
          <p className={styles.terms_text}>
           
      {
        info?.termsAndConditions
      }
      </p>
            
            <button className={styles.termsAgreeButton} onClick={onClose}> Agree</button>
        </div>

        </div>

          
            </ModalBody>
  
       
          </ModalContent>
        </Modal>
      </>
    )
  }
  export default AnalyzeResultModal