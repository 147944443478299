import React from "react";
import styles from "../liveDebates.module.css";
import { format } from "timeago.js";
import { useNavigate } from "react-router-dom";
import { AmIParticipants } from "../../../utils/services";
import { useSelector } from "react-redux";

export const LiveDebateItem = ({ debate }) => {
  const { data } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleJoinDebate = () => {
    if (!data?._id) {
      navigate("/login");
    } else {
      console.log("debug admin 1", data?.isAdmin);
      if (
        AmIParticipants(debate.teams, data?._id) ||
        data?.isCoach ||
        data?.isAdmin
      ) {
        console.log("debug admin 2", data?.isAdmin);

        if (!data?.subscription?.active && !data?.isCoach && !data?.isAdmin) {
          console.log("debug admin 3", data?.isAdmin);

          navigate("/subscription");
        } else {
          navigate(`/debate/${debate.passcode}`);
        }
      } else {
        
        navigate(`/debate/${debate?.passcode}?audience=true`);
      }
    }
  };

  return (
    <div key={debate?._id} className={styles.liveDebateItem}>
      <div>
        <h5 className={styles.debateTopic}> 💥 {debate?.topic}</h5>
        <p className={styles.started_time_ago}>{format(debate?.startTime)}</p>
      </div>
      <div
        className={styles.joinBtnLink}
        // to={`/debate/${debate?.passcode}?audience=true`}
        onClick={handleJoinDebate}
      >
        <button className={styles.join_button}>
          <p>Join</p>
          <img
            width="64"
            height="64"
            src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-link-web-flaticons-lineal-color-flat-icons-8.png"
            alt="external-link-web-flaticons-lineal-color-flat-icons-8"
          />
        </button>
      </div>
    </div>
  );
};
