import React from "react";
import Navbar from "../../Layouts/Navbar/Navbar";
import { calculateAge } from "../../utils/services";
import LiveChat from "../../components/DebateRoom/LiveChat/LiveChat";
import JudgeDebateStartCountDownUi from "../../components/JudgeDebate/JudgeDebateStartCountDown/JudgeDebateStartCountDownUi";
import JudgeDebateScreenUi from "../../components/JudgeDebate/JudgeDebateScreen/JudgeDebateScreenUi";

const JudgeDebateView = ({
  debate,
  debateState,
  startTimeCountDown,
  dispatch,
  isMicMuted,
  JudgeDebate 
}) => {
  if (!debate) {
    return "";
  }
  const currentMotion =
    debate.tournament_motions[debateState.completed_motions];
  return (
    <div>
      <Navbar />
      <div className="flex flex-col gap-y-10 max-w-[1200px] mx-auto">
        <div>
          <div className="flex flex-col gap-y-3">
            <h1 className="text-3xl font-semibold">Judge debate</h1>
            <div className="flex  gap-x-1 underline">
              Judge :
              <p className="">
                {debate?.judge_id?.firstName} {debate?.judge_id?.lastName || ""}
              </p>
            </div>
          </div>
          <p>{debate?.description}</p>
        </div>
        {debateState.isLive ? (
          <JudgeDebateScreenUi
            debateState={debateState}
            debate={debate}
            teams={currentMotion.teams || []}
            dispatch={dispatch}
            isMicMuted={isMicMuted}
            JudgeDebate={JudgeDebate}
          />
        ) : (
          <JudgeDebateStartCountDownUi
            startTimeCountDown={startTimeCountDown}
          />
        )}

        <div className="flex  gap-x-5">
          <div className="flex-[3]">
            <div className="flex flex-col gap-y-5">
              <div className="flex flex-col gap-y-2">
                <div className="flex gap-y-3 flex-col">
                  <div className="flex flex-col gap-y-4 justify-between pr-2 ">
                    <div></div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-y-5 ">
                {debate?.tournament_motions?.map((motion) => (
                  <div className="flex flex-col gap-y-3   py-4 shadow-sm">
                    <div className="flex flex-col ">
                      <p className="font-medium text-sm">{motion.title}</p>
                      <div className=" rounded-md font-bold text-gray-600  w-fit text-[12px]">
                        {motion.debate_type}
                      </div>
                    </div>
                    <div>
                      {motion.teams?.map((team) => (
                        <div className="flex items-center gap-x-1">
                          <b>{team.name} :</b>
                          <div className="text-sm flex items-center gap-x-1">
                            {Array.from(new Array(team.max_members)).map(
                              (mem, index) =>
                                team.members[index]?.firstName ? (
                                  <div className="  px-1 rounded-sm">
                                    {team.members[index]?.firstName}
                                    {calculateAge(team.members[index]?.dob) ||
                                      ""}
                                  </div>
                                ) : (
                                  <div className="bg-green-400 text-white  px-1 rounded-sm">
                                    <p>-</p>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      ))}

                      {/* <div className="flex items-center gap-x-1">
                      <b>CON :</b>
                      <div className="text-sm flex items-center gap-x-1">
                        Ram Harish, <p className="text-green-400">Available</p>
                        <p className="text-green-400">Available</p>{" "}
                      </div>
                    </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* tournament motion details */}
          </div>
          <LiveChat />
        </div>
      </div>
      <div>{/*  */}</div>
    </div>
  );
};

export default JudgeDebateView;
