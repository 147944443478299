import React, { useEffect, useState } from 'react'
import Navbar from '../../../Layouts/Navbar/Navbar'
import styles from "./Info.module.css"
import WeeklyDebate from '../../../components/Admin/WeeklyDebate/WeeklyDebate'
import { debaiInfoApi } from '../../../utils/Api'
import { useSelector } from 'react-redux'
import TermsAndConditions from '../Maintainance/TermsAndConditions'

const Info = () => {
    const [debaiInfo,setDebaiInfo] = useState({});
    const {refresh} = useSelector(state=>state.other)
    useEffect(()=>{
        fetchDebaiInfo()
    },[refresh])
    const fetchDebaiInfo= async()=>{
        try {
           const {data,status}  =  await debaiInfoApi()
           if(status===200){
            setDebaiInfo(data.message[0])
           }
            
        } catch (error) {
                console.log(error)
        }
    }
  return (
    <div className={styles.infoContainer}>
       
    <div className={styles.infoWrapper}>

        <WeeklyDebate info={debaiInfo}/>
        <TermsAndConditions info={debaiInfo}/>



    </div>
    </div>
  )
}

export default Info