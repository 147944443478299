import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
} from "@chakra-ui/react";
// import styles from "./DebateFinishModal.module.css"
// import { useEffect } from 'react';
import "../../../pages/Auth/Auth.css";
import { useEffect, useState } from "react";
import SelectCountry from "../../popovers/selectCountry/SelectCountry";
import { DebAiCountriesList } from "../../../utils/data";
import { getCountries, updateUserapi } from "../../../utils/Api";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "../../../hook/useAlert";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../redux/store";

function UpdateCountryModal({ children  }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [countries, setCountries] = useState([]);
    const [countryData, setCountryData] = useState({ country: "" });
    
  const { data } = useSelector((state) => state.user);
    const { open } = useAlert();
      const dispatch = useDispatch();

  const { SetRefreshNow } = bindActionCreators(actionCreators, dispatch);
  useEffect(() => {
    handleGetCountries();
  }, [DebAiCountriesList]);

  const handleGetCountries = async () => {
    try {
      const { data, status } = await getCountries();
      if (status !== 200) return;
      const filterdCountries = data.filter((count) =>
        DebAiCountriesList.find((name) => {
          return name === count?.name?.common;
        })
      );

      setCountries(filterdCountries);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    if (!countryData.country) {
      return open({
        type: "error",
        text: "Please select a country",
      });
    }
    try {
      const { status } = await updateUserapi(data?._id, countryData);
      console.log("status", status);
      if (status === 200) {
        open({ type: "success", text: "Account updated successfully" });
        onClose();
        SetRefreshNow();
      }
    } catch (error) {
        console.log("error", error);
      open({
        type: "error",
        text: "Account failed to update",
      });
    }
  };
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={"95%"} maxWidth={"500px"}>
          {/* <ModalCloseButton /> */}
          <ModalHeader display={"flex"} gap={"10px"}>
            <h1 className="font-bold text-2xl text-blue-500">
              Update your country !!
            </h1>
          </ModalHeader>
          <ModalBody className="flex flex-col gap-y-3">
            <div className="auth_input_item">
              <SelectCountry
                countries={countries}
                handleInputChange={setCountryData}
              >
                <div
                  className="input_element country_input"
                  style={{
                    color: "rgba(128, 128, 128, 0.678)",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p>
                    {countryData.country
                      ? countryData.country
                      : "select country"}
                  </p>
                </div>
              </SelectCountry>
            </div>
            <button
              onClick={()=>handleUpdate()}
              className="h-[50px] bg-blue-500 w-[120px] text-white tracking-wide  rounded-md"
            >
              Update
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
export default UpdateCountryModal;
