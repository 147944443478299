import styles from "../coaches.module.css"
import { useEffect, useState } from "react"
import { getFlag } from "../../../utils/services"
import CalendlyModal from "../../../Layouts/modal/Calendenly/CalendlyModal"

const CoachItem = ({data ,format}) => {

    const [flag,setFlag] =useState("")

    useEffect(()=>{

      async function getFlagfunc(){

        try {
         setFlag(await getFlag(data.country));
          
        } catch (error) {
          console.log("error",error)
        }
      }
      getFlagfunc()
    }, [data])
  

  return (
     <CalendlyModal flag={flag} coach={data} format={format}>
    <div className={styles.coach_box}>
    <div className={styles.coach_img_wrapper}>

      <img src={data.image} alt="coach" />
    </div>
    <div className={styles.coach_bottom_content}>
    

      <p className={styles.coach_desc}> <b>{data.name} <img className={styles.coachFlag} src={flag} alt="" /> : </b> {data.desc}</p>


   
    </div>
  </div>
      </CalendlyModal>
  )
}

export default CoachItem