

import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
 
  } from '@chakra-ui/react'
 import styles from "./calendlyModal.module.css"

import { InlineWidget } from 'react-calendly';


  function CalendlyModal({ children  ,coach ,flag ,format}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <>
      <span  style={{flex:1}} onClick={onOpen}>
        {children}
      </span>
        <Modal isOpen={isOpen } onClose={onClose}   >

          <ModalOverlay />
          <ModalContent  width={"95%"} maxWidth={"900px"}>
            <div className={styles.calendly_coach_box}>

        <div className={styles.coach_info}>
            <img className={styles.avatar_img} src={coach.image}   alt="" />   
            

            <h1 className={styles.coach_name_text}> {coach?.name }</h1>
            <img className={styles.flag_img} src={flag} alt="" />
          </div>
          {
            format ? coach?.desc?.split("|")?.map((d,i)=><p  className={styles.formatedParagph} key={i}>{d}</p>) :  <p className={styles.coach_desc_text}>{coach?.desc}</p>
          }
        

            </div>
                <InlineWidget styles={{width:"100%",height:"600px"}}  url={coach?.calendlyLink} />
          </ModalContent>
        </Modal>
      </>
    )
  }
  export default CalendlyModal