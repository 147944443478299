import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createDebateApi, getIsPassocodeUniqueApi } from "../../utils/Api";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import "./DebateFormInput.css";
import { generateRandomNumber } from "../../utils/services";
import { BitrishParliamentaryTeamFormat, debateFormat } from "../../utils/data";
import MainDebateInput from "./MainDebateInput";
import Teams from "./Teams";
import TeamFormat from "./TeamFormat";
import ConfirmCreate from "./ConfirmCreate";
import { BsArrowReturnLeft } from "react-icons/bs";
import { Enums } from "../../redux/action/actionTypes/Enumss";
import { debateFormatEnum } from "../../utils/enum";

const DebateFormInput = ({ stepIndex, setStepIndex }) => {
  const { data: currentUser } = useSelector((state) => state.user);
  const [duration, setDuration] = useState({
    hour: 1,
    minute: 0,
  });
  const [durationType, setDurationType] = useState("Set Duration");
  const [instantDebateTimes, setInstantDebateTimes] = useState("1Minute");
  const [startTime, setStartTime] = useState(new Date());
  const [formLevel, setFormLevel] = useState(0);
  const navigate = useNavigate();
  const toast = useToast();
  const [isPasscodeValid, setIsPasscodeValid] = useState(true);
  const [teamNameError, setTeamNameError] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [debateForm, setDebateForm] = useState({
    type: "",
    topic: "",
    duration: 0,
    isPublic: "",
    passcode: "",
    judgeType: "",
    startTime: 0,
    timeFormat: [],
    intervalTime: 10,
    chooseSide: false,
    admin: currentUser?._id,
    teams: [
      {
        name: "PRO",
        members: [],
      },
      {
        name: "CON",
        members: [],
      },
    ],
  });

  const [refresh, setREfresh] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setREfresh((prev) => !prev);
    }, 1000);
  }, []);

  useEffect(() => {
    if (durationType === "Instant Debate") {
      handleInstatDebateChange("1Minute");
    } else if (durationType === "Set Duration") {
      setDuration({
        minute: 0,
        hour: 1,
      });
    }
  }, [durationType]);

  useEffect(() => {
    if (debateForm.chooseSide) {
      setDebateForm((prev) => ({
        ...prev,
        teams: prev.teams.map((t, i) => {
          if (i === 0) {
            return { ...t, name: "Unknown" };
          } else {
            return { ...t, name: "Unknown " };
          }
        }),
      }));
    } else {
      setDebateForm((prev) => ({
        ...prev,
        teams: prev.teams.map((t, i) => {
          if (i === 0) {
            return { ...t, name: "PRO" };
          } else {
            return { ...t, name: "CON" };
          }
        }),
      }));
    }
  }, [debateForm.chooseSide]);

  useEffect(() => {
    if (!currentUser) return;
    setDebateForm((prev) => ({
      ...prev,
      admin: currentUser?._id,
    }));
  }, [currentUser]);
  useEffect(() => {
    let durationInMs = 1000 * 60 * (duration.hour * 60 + duration.minute);
    setDebateForm((prev) => ({
      ...prev,
      duration: durationInMs,
    }));
  }, [duration]);
  useEffect(() => {
    checkIsDebateInputCompleted();
  }, [debateForm]);

  useEffect(() => {
    if (debateForm.type) {
      if (debateForm.type === debateFormatEnum["British Parliamentary"]) {
        setDebateForm((prev) => ({
          ...prev,
          teams: BitrishParliamentaryTeamFormat,
        }));
      } else {
        setDebateForm((prev) => ({
          ...prev,
          teams: [
            {
              name: "PRO",
              members: [],
            },
            {
              name: "CON",
              members: [],
            },
          ],
        }));
      }
      let max_team_members = debateFormat.get(debateForm.type);
      if (
        debateForm.teams.find((team) => team.members.length > max_team_members)
      ) {
        setDebateForm((prev) => ({
          ...prev,
          teams: prev.teams.map((team) => ({
            ...team,
            members: [],
          })),
        }));
      }

      // if (debateForm.teams[index]?.members?.length >= max_team_members[0]) {
      //   error = `${debateForm.type} can't have more than ${max_team_members} members in each team`;
      // }
    }
  }, [debateForm.type]);

  const checkIsDebateInputCompleted = () => {
    let missing = false;
    for (let key in debateForm) {
      if (!debateForm[key] && key !== "teams") {
        missing = true;
      }
    }
    if (!missing) {
      setFormLevel(1);
    }
  };
  const handleTeamName = (event, teamIndex) => {
    setDebateForm((prev) => ({
      ...prev,
      teams: prev.teams.map((team, index) => {
        if (index === teamIndex) {
          return { ...team, name: event.target.value };
        } else {
          return team;
        }
      }),
    }));
  };
  const handleInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "participantsCount" && parseInt(value) > 8) {
      return;
    }
    if (name === "participantsCount" || name === "speakTime") {
      value = parseInt(value);
    }
    setDebateForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCreateDebate = async () => {
    if (isCreating) return;


    if (!currentUser) {
      handleShowAlert(`You need to login first.`, "error");
      return;
    }

    let theFormCopy = { ...debateForm };

    let theTeams = theFormCopy.teams.map((team) => ({
      ...team,
      members: team.members.map((member) => member._id),
    }));

    // payloadData.teams;
    let thePayload = {
      ...theFormCopy,
      teams: theTeams,
      admin: currentUser?._id,
    };

    const isValid = handleFormValidate(thePayload);
    let teamMebersCount = thePayload.teams.reduce((acc, item) => {
      let theLen = item.members.length;
      return acc + theLen;
    }, 0);
    thePayload.isPublic = thePayload.isPublic === Enums.PUBLIC_DEBATE;
    thePayload.participantsCount = teamMebersCount;

    if (isValid) {
      setIsCreating(true);
      try {
        const res = await createDebateApi(thePayload);
        
        if (res.status === 200) {
          const { message } = res.data;
          handleShowAlert(`Debate successfully created.`, "success");
          navigate(`/created/${message?._id}`);
        } else {
          throw Error(res.data.message);
        }
      } catch (error) {
        setIsCreating(false);
        handleShowAlert(`Something went wrong..`, "error");

        // console.log(error.message)
      }
    }
  };
  const handleStartDate = (value) => {
    setStartTime(value);
    let startDateInMs = new Date(value).getTime() + 5000 < new Date().getTime();
    if (startDateInMs) {
      // handleShowAlert( `Choose the future date.`,"error")
      value = Date.now();
    }

    setDebateForm((prev) => ({
      ...prev,
      startTime: new Date(value).getTime(),
    }));
  };
  //
  const handleInstatDebateChange = (string) => {
    let durationInMs = 1000 * 60;
    if (string === "1Minute") {
      durationInMs *= 1;
      setInstantDebateTimes("1Minute");
    } else if (string === "2Minute") {
      setInstantDebateTimes("2Minute");
      durationInMs *= 2;
    } else {
      setInstantDebateTimes("3Minute");
      durationInMs *= 3;
    }
    setDebateForm((prev) => ({
      ...prev,
      duration: durationInMs,
    }));
  };

  const handleFormValidate = (payload) => {
    const missingField = [];
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      if (key !== "chooseSide") {
        if (!value) {
          missingField.push(key);
        }
      }
    });
    // alert(`${missingField.map((key) => key).join("/") } fields are missing`)
    if (missingField.length > 0) {
      handleShowAlert(
        `${missingField.map((key) => key).join(",")} fields are missing`,
        "error"
      );
      return false;
    }

    if (payload.duration <= 0) {
      handleShowAlert(" `Duration should not be  0`", "error");

      return false;
    }
    return true;
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };
  const returnTeamFormatOptions = () => {
    let formatArr = debateFormat.get(debateForm.type);
    if (formatArr) {
      return formatArr.map((format) => (
        <option
          key={format}
          value={format.toString()}
          name={debateForm.type}
        >{`${format} vs ${format}`}</option>
      ));
    }
  };

  const handlePassCodeChange = async (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9]/g, "");
    if (value.length > 6) return;

    setDebateForm((prev) => ({
      ...prev,
      passcode: +value,
    }));
    if (value.length === 6) {
      const { data, status } = await getIsPassocodeUniqueApi(value);
      if (status === 200) {
        const { isUnique } = data.message;
        if (isUnique) {
          setIsPasscodeValid(true);
          setDebateForm((prev) => ({ ...prev, passcode: +value }));
        } else {
          handleShowAlert("This code is already used try next ", "error");
          setIsPasscodeValid(false);
          setDebateForm((prev) => ({
            ...prev,
            passcode: "",
          }));
        }
      }
    }
  };
  const handleAutoGenerateePasscode = async () => {
    let stop = false;

    do {
      const passcode = generateRandomNumber();
      const { data, status } = await getIsPassocodeUniqueApi(passcode);
      if (status === 200) {
        const { isUnique } = data.message;
        if (!isUnique) {
          setIsPasscodeValid(false);
        } else {
          setIsPasscodeValid(true);
          setDebateForm((prev) => ({
            ...prev,
            passcode,
          }));
          stop = true;
        }
      }
    } while (!stop);
  };

  const handleShowAlert = (text, type) => {
    toast({
      description: text,
      status: type,
      duration: 5000,
      position: "top",
      isClosable: true,
    });
  };

  const handleDebateTopicChange = (topic) => {
    setDebateForm((prev) => ({ ...prev, topic }));
  };

  const goToNext = () => {
    const {
      topic,
      type,
      timeFormat,
      startTime,
      judgeType,
      passcode,
      isPublic,
      intervalTime,
    } = debateForm;
    if (stepIndex === 0) {
      if (
        !topic ||
        !type ||
        !timeFormat ||
        !startTime ||
        !judgeType ||
        !passcode ||
        !isPublic
      ) {
        return false;
      }
    } else if (stepIndex === 1) {
      const hasTeamName = debateForm.teams.every((team) => team.name);
      if (!hasTeamName) {
        handleShowAlert(" Team name is required", "error");
        return false;
      }
      if (
        debateForm.teams[0]?.name === debateForm.teams[1]?.name &&
        !debateForm.chooseSide
      ) {
        console.log("inside check", debateForm.chooseSide);
        handleShowAlert(" Teams should not have the same name", "error");
        return false;
      }
      const hasMoreThanOneMember = debateForm.teams.every(
        (team) => team.members.length >= 1
      );
      if (!hasMoreThanOneMember) {
        handleShowAlert(" Add atleast one member", "error");
        return false;
      }
      if (teamNameError) {
        handleShowAlert("Team name should only have characters.");
        return false;
      }
    } else if (stepIndex === 3) {
      if (!intervalTime) return false;
    }
    return true;
  };

  const handleGoToNext = () => {
    const canGo = goToNext();
    if (canGo) {
      setStepIndex((prev) => prev + 1);
    }
  };

  const debateStepMap = {
    0: (
      <MainDebateInput
        refresh={refresh}
        debateForm={debateForm}
        handleAutoGenerateePasscode={handleAutoGenerateePasscode}
        handleInputChange={handleInputChange}
        handleStartDate={handleStartDate}
        handleDebateTopicChange={handleDebateTopicChange}
        handlePassCodeChange={handlePassCodeChange}
        startTime={startTime}
        durationType={durationType}
        filterPassedTime={filterPassedTime}
        handleInstatDebateChange={handleInstatDebateChange}
        instantDebateTimes={instantDebateTimes}
        isPasscodeValid={isPasscodeValid}
        returnTeamFormatOptions={returnTeamFormatOptions}
      />
    ),
    1: (
      <Teams
        handleTeamName={handleTeamName}
        setDebateForm={setDebateForm}
        debateForm={debateForm}
        setTeamNameError={setTeamNameError}
        teamNameError={teamNameError}
        formLevel={formLevel}
      />
    ),

    2: <TeamFormat debateForm={debateForm} setDebateForm={setDebateForm} />,

    3: (
      <ConfirmCreate
        isCreating={isCreating}
        currentUser={currentUser}
        handleCreateDebate={handleCreateDebate}
      />
    ),
  };

  return (
    <div className="DebateFormWrapper">
      {debateStepMap[String(stepIndex)]}

      <div className="create_debate_bottom_button">
        {stepIndex !== 0 && (
          <button
            onClick={() => setStepIndex((prev) => prev - 1)}
            className="back_button"
          >
            <BsArrowReturnLeft className="backIcon" />
          </button>
        )}
        {stepIndex !== 3 && (
          <button className={`next_debate_step`} onClick={handleGoToNext}>
            {" "}
            NEXT{" "}
          </button>
        )}
      </div>
    </div>
  );
};

export default DebateFormInput;
