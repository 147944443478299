import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./debCreated.module.css";
import { getDebateByIdApi } from "../../utils/Api";
import { useEffect, useState } from "react";
import Navbar from "../../Layouts/Navbar/Navbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import useAlert from "../../hook/useAlert";
import { LuClipboardList } from "react-icons/lu";
import { IoNavigateCircleOutline } from "react-icons/io5";
import { AmIParticipants } from "../../utils/services";
import { useSelector } from "react-redux";

const DebCreated = () => {
  const [debateData, setDebateData] = useState({});
  const { debateId } = useParams();
  const { data } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { open } = useAlert();
  useEffect(() => {
    if (debateId) fetchDebateById();
  }, [debateId]);
  const fetchDebateById = async () => {
    try {
      const { data } = await getDebateByIdApi(debateId);
      setDebateData(data.message[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleJoinDebate = () => {
    if (!data?._id) {
      navigate("/login");
    } else {
      if (AmIParticipants(debateData.teams, data?._id) || data?.isCoach) {
        if (!data?.subscription?.active && !data?.isCoach) {
          navigate("/subscription");
        } else {
          navigate(`/debate/${debateData.passcode}`);
        }
      } else {
        navigate(`/debate/${debateData?.passcode}?audience=true`);
      }
    }
  };
  return (
    <>
      <Navbar />
      <div className={styles.debateCreated}>
        <div className={styles.debateCreatedBox}>
          <h1 className={styles.debateName}>{debateData?.topic} </h1>

          <div className={styles.debateInfo}>
            Congratulations, your debate was created successfully! Now share the
            6 digit code below and get your critical gears whirring!
          </div>
          <div className="flex gap-3 items-center cursor-pointer">
            <div className={styles.passcodeBox} onClick={handleJoinDebate}>
              <p> {debateData?.passcode && `${debateData.passcode}`} </p>
              <IoNavigateCircleOutline className={styles.navigateIcon} />
            </div>
            {/* <div className={styles.joinDebateButton} >
              <p>Join Debate</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DebCreated;
