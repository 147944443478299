
import Box from '@mui/material/Box';
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

function Media() {


  return (
    <Box sx={{display:"flex" ,flexWrap:"wrap" ,gap:"1rem ", width:"100%"}}>
  

        {
          Array.from(new Array(20).fill(0)).map(item=>(
            <Box  boxShadow='lg' sx={{ p:4, borderRadius:"8px" ,flex:1, minWidth:"400px" ,gap:"10px"}} >
       
          <Box padding='6'  bg='white' width={"100%"} display={"flex"} gap={"5px"}>

          <SkeletonCircle size="50px" />
                <SkeletonCircle size="50px" />
          </Box>
           <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='7'  width={"full"}/>
        
        </Box>
             
           ))
         } 
   
    </Box>
  );
}

export default function DebateSkeleton() {
  return (
    <Box sx={{ overflow: 'hidden',width:"100%" }}>
      <Media  />
    </Box>
  );
}