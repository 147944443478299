import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useDispatch } from "react-redux";
import AgoraRTM from "agora-rtm-sdk";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import DebateScreenBox from "../../Layouts/Debate/DebateScreenBox/DebateScreenBox";
import Participants from "../../Layouts/Debate/Participants/Participants";
import Navbar from "../../Layouts/Navbar/Navbar";
import LiveChat from "../../components/DebateRoom/LiveChat/LiveChat";
import { getDebateByPassocde } from "../../utils/Api";
import {
  AmIParticipants,
  DebateRoomServices,
  getTimeFromMs,
} from "../../utils/services";
import { actionCreators } from "../../redux/store";
import DebateAction from "../../components/DebateRoom/DebateAction/DebateAction";
import "./DebateRoom.css";
import AnalyzeResultModal from "../../Layouts/modal/DebateFinishedModal/AnalyzingResult";
import SpeakTimeLeft from "../../Layouts/Debate/SpeakTimeLeft/SpeakTimeLeft";
import DebateRoomHeader from "./DebateRoomHeader/DebateRoomHeader";
import CoachDecision from "../../components/DebateRoom/CoachDecision/CoachDecision";
import { Enums } from "../../redux/action/actionTypes/Enumss";
import useAlert from "../../hook/useAlert";
import { useAudioStream } from "../../redux/context/AudioStreamContext";
const APPID = process.env.REACT_APP_AGORA_APP_ID;
const Rtm_client = AgoraRTM.createInstance(APPID);
const Rtc_client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
const rtcUid = Math.floor(Math.random() * 2032);
export { Rtm_client, Rtc_client };

const DebateRoom = () => {
  const { isLive, isUserParticipant, roomLoading } = useSelector(
    (state) => state.debate
  );
  const audioStream = useAudioStream();
  const { open } = useAlert();
  const { data } = useSelector((state) => state.user);
  const otherState = useSelector((state) => state.other);
  const [UrlSearchParams, setUrlSearchParams] = useSearchParams();
  const [WatchType, setWatchType] = useState();
  let { debateId } = useParams();
  debateId = debateId.toString();
  const dispatch = useDispatch();
  const [speech, setSpeech] = useState();
  const rtmChannelRef = useRef();
  const [coach, setCoach] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const {
    AddActiveDebate,
    addLiveMessages,
    SetRoomIsLiveOrNot,
    SetIsUserParticipant,
    setRtmChannelAction,
    SetRoomLoading,
    setRoomService,
  } = bindActionCreators(actionCreators, dispatch);

  const [activeMicControlTeam, setActiveMicControlTeam] = useState(null);
  const [allUsers, setAllusers] = useState([]);
  const intervalRef = useRef([]);
  const [remainingTime, setRemainingTime] = useState({
    day: null,
    hour: null,
    min: null,
    sec: null,
  });
  const [audioTracks, setAudioTracks] = useState({
    localAudioTracks: null,
    remoteAudioTracks: {},
  });
  const toast = useToast();
  const navigate = useNavigate();
  const debateStateRef = useRef();
  const MicElmRef = useRef();
  const activeDebateRef = useRef();
  const timeRemainingRef = useRef();
  const [startCoachDecision, setStartCoachDecision] = useState(false);
  const [micMuted, setMicMuted] = useState(true);
  const [RoomMembers, setRoomMembers] = useState([]);
  const [activeSpeakers, setActiveSpeakers] = useState([]);
  const [startAnalyze, setStartAnalyze] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState({ 1: "", 2: "", 3: "", 4: "" });
  const lastApiCallConfig = useRef({
    admin: null,
    hasApiCalled: false,
    startApiCalled: false,
  });
  const [debateState, setDebateState] = useState({
    endAt: null,
    intervalRef: false,
    startedAt: null,
    speakTeam: null,
    speakTime: null,
    isStarted: false,
    hasFinished: false,
    round_shot: null,
    changedAt: null,
    noOfRounds: null,
    both: null,
    timeFormat: null,
    isInterval: false,
  });
  const showToast = (message, type) => {
    toast({
      title: "",
      description: message,
      status: type,
      duration: 5000,
      position: "top",
      isClosable: true,
    });
  };
  const hasLeftRoom = useRef(false);
  const [winTeamName, setWinTeamName] = useState("");
  const [speakTimeLeft, setSpeakTimeLeft] = useState(null);
  const RoomService = new DebateRoomServices({
    data,
    isLive,
    rtcUid,
    winTeamName,
    setWinTeamName,
    setCoach,
    navigate,
    hasLeftRoom,
    MicElmRef,
    debateId,
    micMuted,
    open,
    showToast,
    otherState,
    addLiveMessages,
    audioTracks,
    startCoachDecision,
    setShowResult,
    setStartCoachDecision,
    RoomMembers,
    setMicMuted,
    rtmChannelRef,
    activeSpeakers,
    audienceList,
    setDebateState,
    setAudienceList,
    setSpeakTimeLeft,
    setAllusers,
    setRoomMembers,
    allUsers,
    debateStateRef,
    AddActiveDebate,
    intervalRef,
    activeDebateRef,
    setResult,
    timeRemainingRef,
    result,
    lastApiCallConfig,
    setActiveSpeakers,
    setRtmChannelAction,
    activeMicControlTeam,
    setActiveMicControlTeam,
    setRoomLoading: SetRoomLoading,
    isAudience: UrlSearchParams.get("audience"),
  });
  const startsIntervalIdRef = useRef();
  const [gapCountDown, setGapCountDown] = useState({
    sec: 0,
  });

  useEffect(() => {
    setRoomService(RoomService);
  }, []);
  useEffect(() => {
    if (
      activeDebateRef?.current?.hasEnded ||
      rtmChannelRef.current ||
      !audioStream
    )
      return;
    handleNavigate();
  }, [activeDebateRef.current, data, rtmChannelRef.current, audioStream]);
  useEffect(() => {
    return async () => {
      if (hasLeftRoom.current) return;
      await RoomService.closeTracks();
      await RoomService.removeParticipant();
      AddActiveDebate(null);
    };
  }, []);
  useEffect(() => {
    if (activeDebateRef.current?.winner && activeDebateRef.current?.hasEnded) {
      console.log("navigating");
      navigate(`/completion/${activeDebateRef.current?._id}`);
    }
  }, [activeDebateRef.current]);
  useEffect(() => {
    if (activeDebateRef.current) {
      const {
        admin: { _id },
      } = activeDebateRef.current;
      lastApiCallConfig.current = {
        ...lastApiCallConfig.current,
        admin: _id,
      };
    }
  }, [activeDebateRef.current]);

  useEffect(() => {
    debateStateRef.current = debateState;
  }, [debateState]);

  useEffect(() => {
    if (UrlSearchParams.get("audience")) {
      setWatchType("AUDIENCE");
    } else {
      setWatchType("PARTICIPANT");
    }
  }, [UrlSearchParams]);

  useEffect(() => {
    if (!debateId) return;
    fetchDebateById();
  }, [debateId]);

  useEffect(() => {
    let now = new Date().getTime();
    if (now < activeDebateRef.current?.startTime) {
      SetRoomIsLiveOrNot(false);
    } else {
      SetRoomIsLiveOrNot(true);
    }
  }, [activeDebateRef.current, data]);

  useEffect(() => {
    if (debateState?.isStarted === false) {
      if (activeDebateRef?.current) {
        const { startTime } = activeDebateRef?.current;
        startsIntervalIdRef.current = setInterval(() => {
          if (startTime > Date.now()) {
            const { sec, min, hour, day } = getTimeFromMs(
              activeDebateRef?.current?.startTime
            );
            if (min === 15 && sec === 0 && hour === 0) {
              fetchDebateById();
            }

            setRemainingTime({
              sec,
              day,
              hour,
              min,
            });
          } else {
            SetRoomIsLiveOrNot(true);
            clearInterval(startsIntervalIdRef.current);
          }
        }, 1000);
      }
    }
  }, [activeDebateRef?.current, debateState]);

  useEffect(() => {
    if (!activeDebateRef.current) return;
    if (data) {
      let isParticipant = activeDebateRef.current?.teams.some((team) =>
        team.members.some((member) => member._id === data?._id)
      );
      if (isParticipant || data.isCoach) {
        SetIsUserParticipant(true);
      } else {
        SetIsUserParticipant(false);
      }
    } else {
      SetIsUserParticipant(false);
    }
  }, [data, activeDebateRef.current?.teams]);

  useEffect(() => {
    if (!roomLoading) return;

    if (
      isLive &&
      !UrlSearchParams.get("audience") &&
      activeSpeakers.length > 0
    ) {
      SetRoomLoading(false);
    }
    if (!isLive && activeDebateRef.current) {
      SetRoomLoading(false);
    }

    if (isLive && UrlSearchParams.get("audience") && activeDebateRef.current) {
      SetRoomLoading(false);
    }
  }, [isLive, activeSpeakers, activeDebateRef.current]);

  useEffect(() => {
    if (activeDebateRef.current?.state?.hasFinished) {
      setDebateState((prev) => ({
        ...prev,
        hasFinished: true,
        isStarted: true,
      }));
    }
    if (
      !Rtm_client ||
      !rtmChannelRef.current ||
      !activeDebateRef.current ||
      intervalRef.current.length > 0
    )
      return;
    const activeDebate = activeDebateRef.current;

    if (activeDebate?.state?.hasFinished) {
      return;
    }
    RoomService.handleDebateState();
  }, [rtmChannelRef.current, activeDebateRef?.current, Rtm_client]);

  useEffect(() => {
    if (lastApiCallConfig.current.hasApiCalled) {
      navigate(`/completion/${activeDebateRef.current._id}`);
    }
  }, [lastApiCallConfig.current.hasApiCalled]);

  useEffect(() => {
    if (lastApiCallConfig.current.startApiCalled) {
      setStartAnalyze(true);
    }
  }, [lastApiCallConfig.current.startApiCalled]);

  const handleNavigate = async () => {
    if (!activeDebateRef.current || !data) return;
    if (
      AmIParticipants(activeDebateRef.current?.teams, data?._id) &&
      !data?.subscription?.active &&
      !data.isAdmin &&
      !data.isCoach
    ) {
      navigate("/subscription");
    } else {
      await RoomService.getAgoraToken(audioStream);
    }
  };

  const fetchDebateById = async () => {
    if (!debateId) return;
    try {
      const res = await getDebateByPassocde(debateId);
      if (res.status !== 200) throw Error(res.data.message);
      activeDebateRef.current = res.data.message[0];
      AddActiveDebate(activeDebateRef);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleGetChannelAttribute = async () => {
    const attr = await RoomService.getChannelAttributeFunc();
    let speechText = attr?.speechText?.value;
    if (attr && speechText) {
      const thePast = speechText ? JSON.parse(speechText) : {};
      setSpeech(thePast);
    }
  };

  setTimeout(() => {
    handleGetChannelAttribute();
  }, 5000);

  return (
    <>
      <Navbar />
      {startAnalyze && (
        <AnalyzeResultModal activeDebate={activeDebateRef.current} />
      )}
      {debateState?.isStarted &&
        activeDebateRef?.current?.judgeType === Enums.COACHJUDGE && (
          <CoachDecision
            showResult={showResult}
            startCoachDecision={startCoachDecision}
            winTeamName={winTeamName}
            setWinTeamName={setWinTeamName}
            RoomService={RoomService}
            coach={coach}
            result={result}
            setResult={setResult}
            debateState={debateState}
            activeDebate={activeDebateRef.current}
          />
        )}
      <div
        onClick={() => RoomService.getChannelAttributeFunc()}
        className="DebateRoomWrapper"
      >
        <DebateRoomHeader
          isLive={isLive}
          debateState={debateState}
          gapCountDown={gapCountDown}
          speakTimeLeft={speakTimeLeft}
          remainingTime={remainingTime}
          activeDebateRef={activeDebateRef}
        />
        <div className="debate_room_top">
          <DebateScreenBox
            activeDebate={activeDebateRef}
            isLive={isLive}
            setGapCountDown={setGapCountDown}
            lastApiCallConfig={lastApiCallConfig}
            setSpeakTimeLeft={setSpeakTimeLeft}
            roomMembers={RoomMembers}
            debateState={debateState}
            activeSpeakers={activeSpeakers}
            timeRemainingRef={timeRemainingRef}
            isUserParticipant={isUserParticipant}
            isNotWatch={WatchType !== "AUDIENCE"}
            activeMicControlTeam={activeMicControlTeam}
            RoomService={RoomService}
            startTeam={activeDebateRef.current?.timeFormat[0].team}
          />
          {isLive && (
            <DebateAction
              activeDebate={activeDebateRef}
              MicElmRef={MicElmRef}
              isLive={isLive}
              roomId={debateId}
              micMuted={micMuted}
              WatchType={WatchType}
              RoomService={RoomService}
              setMicMuted={setMicMuted}
              roomMembers={RoomMembers}
              debateState={debateState}
              micControlTeam={activeMicControlTeam}
              isUserParticipant={isUserParticipant}
              activeMicControlTeam={activeMicControlTeam}
            />
          )}

          <SpeakTimeLeft
            activeDebate={activeDebateRef}
            startTeam={activeDebateRef?.current?.timeFormat[0].team}
            debateState={debateState}
            RoomService={RoomService}
            //  countDown={countDown}
          />
        </div>
        {/* <div>
          {speech &&
            Object.keys(speech).map((key) => {
              return (
                <div>
                  <b>{key}</b>
                  <i>{speech[key]}</i>
                  <br />
                </div>
              );
            })}
        </div> */}
        <div
          className="debate_bottom_content"
          onClick={() => RoomService.getChannelAttributeFunc()}
        >
          <div className="debate_bottom_container">
            <Participants
              coach={coach}
              audienceList={audienceList}
              activeDebate={activeDebateRef}
            />
            <LiveChat activeDebate={activeDebateRef} />
          </div>
        </div>
      </div>
    </>
  );
};
export default DebateRoom;
