import { useSelector } from "react-redux";
import "./DebateScreenBox.css";
import React, { useEffect, useState } from "react";
import DebateScreen from "./DebateScreen/DebateScreen";
import DebateScreenSkeleton from "../../Skeleton/DebateScreenBox/DebateScreenSkeleton";

const DebateScreenBox = React.memo(
  ({ activeDebate, roomMembers, activeSpeakers, debateState }) => {
    const { activeParticipants } = useSelector((state) => state.debate);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
      if (activeDebate?.current && roomMembers) {
        let speakerTeams = roomMembers
          .filter((speaker) => {
            return activeDebate?.current.teams.some((team) => {
              return team.members.some((member) => {
                return member._id === speaker.id;
              });
            });
          })
          .map((speaker) => {
            let team = activeDebate.current.teams.find((team) => {
              return team.members.some((member) => {
                return member._id === speaker.id;
              });
            });

            return { id: speaker.id, teamName: team.name, ...speaker };
          })
          ?.reduce((acc, speaker) => {
            if (acc[speaker.teamName]) {
              acc[speaker.teamName].push(speaker);
            } else {
              acc[speaker.teamName] = [speaker];
            }
            return acc;
          }, {});

        const TeamArray = activeDebate?.current?.teams?.map((team) => ({
          name: team.name,
          members: [],
        }));


        Object.keys(speakerTeams).forEach((team) => {
          TeamArray.forEach((teamObj) => {
            if (teamObj.name === team) {
              teamObj.members = speakerTeams[team];
            }
          });
        });

        const proIndex = TeamArray.findIndex((team) => team.name === "PRO");
        const proTeam = TeamArray.splice(proIndex, 1)[0];
        TeamArray.unshift(proTeam);
        setTeams(TeamArray);
      }
    }, [activeDebate?.current, roomMembers]);

    return (
      <>
        {
          <div className="DebateScreenBoxWrapper">
            {activeDebate?.current && activeParticipants ? (
              teams?.map((team, index) => (
                <DebateScreen
                  key={index}
                  debateState={debateState}
                  activeSpeakers={activeSpeakers}
                  team={team}
                  teamOne={index === 0}
                />
              ))
            ) : (
              <DebateScreenSkeleton />
            )}
          </div>
        }
      </>
    );
  }
);
export default DebateScreenBox;
