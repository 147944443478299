import { useEffect, useState } from "react";
import DebateCard from "../../components/DebateRoom/DebateCard/DebateCard";
import Navbar from "../../Layouts/Navbar/Navbar";
import { getAllDebateApi, getCurrentDebateApi } from "../../utils/Api";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/store";
import { useDispatch } from "react-redux";
import "./CurrentDebates.css";
import { Enums } from "../../redux/action/actionTypes/Enumss";
import Skeleton from "../../Layouts/Skeleton/Skeleton";
import NoLiveDebate from "../../Layouts/Debate/NoLiveDebate/NoLiveDebate";
import DebateSkeleton from "../Skeleton/DebateCardStn/DebateCardStn";

const CurrentDebates = () => {
  const [theDebateArr, settheDebateArr] = useState(null);
  const { currentDebateTab ,liveDebates,upcomingDebates} = useSelector((state) => state.debate);
  // const { data: currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { AddLiveDebateTab, AddUpcominggDebateTab } = bindActionCreators(
    actionCreators,
    dispatch
  );
// console.log("my debates",liveDebates,upcomingDebates)
  useEffect(() => {
    if(currentDebateTab === Enums.LIVE_TAB){
      settheDebateArr(liveDebates)
    }else{
      settheDebateArr(upcomingDebates)
    }

  }, [currentDebateTab,liveDebates,upcomingDebates]);

  const handleChangeDebateTabtype = (type) => {
    if (type === currentDebateTab) return;
    if (type === Enums.LIVE_TAB) {
      AddLiveDebateTab();
    } else {
      AddUpcominggDebateTab();
    }
    settheDebateArr(null);
  };



  return (
    <>
      <Navbar />
      <div className="CurrentDebatesWrapper">
        <div className="currentDebates_bg"></div>
        <div className="current_debates_header">
          <h3 className="live_debates text1">
        <h4 className="debate_live_status_text">
          {currentDebateTab === Enums.UPCOMING_TAB ?   <img width="94" height="94" src="https://img.icons8.com/3d-fluency/94/alarm-clock--v2.png" alt="alarm-clock--v2"/> : <img width="94" height="94" src="https://img.icons8.com/3d-fluency/94/satellite-sending-signal.png" alt="satellite-sending-signal"/>}
        
          {currentDebateTab === Enums.UPCOMING_TAB ? "UPCOMING " : "ONGOING "}
          </h4>    
            DEBATES
          </h3>
          <div className="tab_buttons_wrapper">
            <button
              className={`${
                currentDebateTab === Enums.UPCOMING_TAB
                  ? "active_tab_button"
                  : ""
              }`}
              onClick={() => {
                handleChangeDebateTabtype(Enums.UPCOMING_TAB);
              }}
            >
              Upcoming Debates
            </button>
            <button
              className={`${
                currentDebateTab === Enums.LIVE_TAB ? "active_tab_button" : ""
              }`}
              onClick={() => {
                handleChangeDebateTabtype(Enums.LIVE_TAB);
              }}
            >
              Live Debates
            </button>
          </div>
        </div>

        <div className="debate_wrapper_list">
          {theDebateArr ? (
            theDebateArr.length > 0 ? (
              theDebateArr.map((debate) => (
                <DebateCard
                  key={debate._id}
                  debate={debate}
                  isLive={currentDebateTab === "LIVE_TAB"}
                />
              ))
            ) : (
              <>
                <NoLiveDebate
                  text={
                    currentDebateTab === "LIVE_TAB"
                      ? "No Live Debates"
                      : "No Upcoming Debates"
                  }
                />
              </>
            )
          ) : (
           
          
              <DebateSkeleton/>
            
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentDebates;
