import { useEffect, useState } from "react"
import "./speakTimeLeft.css"
const SpeakTimeLeft = ({activeDebate ,RoomService}) => {
  const [totalDuration,setTotalDuration] =useState(0)
  useEffect(()=>{
    if(!activeDebate?.current)return;
              const total =   activeDebate?.current?.timeFormat?.reduce((t,c)=>{
                if(c.team==="break"){
                  return t ;
                }
                  let tt =  t + Number(c.time);
                  return tt;
                },0)

                setTotalDuration(total)
                 
                
  },[activeDebate?.current])

  return (
         <>
         {
   <div className='speak_time_left_box' >
    <div className="speakTime_left_box_time_show">
 

      </div>
      <div className="speak_time_info">

   


         <div className="speakTime_item" >
            <div className="speakTime_title">

      <img width="96" height="96" src="https://img.icons8.com/fluency/96/diversity.png" alt="diversity"/>            
            <p className="speak_time_key">Debate Type  </p>
            </div>
            
            <p className="speak_time_value">{activeDebate?.current?.type}</p>
       
          </div>       
        <div className="speakTime_item" >
            <div className="speakTime_title">

         <img width="48" height="48" src="https://img.icons8.com/fluency/48/expired.png" alt="expired"/>
            <p className="speak_time_key">Total speech   </p>
            </div>
            
            <p className="speak_time_value">{RoomService?.getNoOfDebates()}</p>
      
          </div>
    

         { activeDebate.current?.timeFormat && <div className="speakTime_item">
              <div className="speakTime_title">
              <img width="94" height="94" src="https://img.icons8.com/3d-fluency/94/clock.png" alt="clock"/>
              <p className="speak_time_key">Duration </p>
              </div> 
              <p className="speak_time_value">
          {
            totalDuration.toString()
          } mins
             
                </p>
            </div>
            }
               <div className="speakTime_item" >
            <div className="speakTime_title">

            <img width="64" height="64" src="https://img.icons8.com/external-flatart-icons-flat-flatarticons/64/external-judge-auction-flatart-icons-flat-flatarticons-1.png" alt="external-judge-auction-flatart-icons-flat-flatarticons-1"/>
            <p className="speak_time_key">Judge Type  </p>
            </div>
            
            <p className="speak_time_value">{activeDebate?.current?.judgeType}</p>
          </div>
      
       


 
    
    
     
          </div>
    </div>
        
      }
        </>
  )
}

export default SpeakTimeLeft