import { getTime, getTimeCountDown } from "../../../utils/services";
import moment from "moment";

const DebateRoomHeader = ({
  debateState,
  isLive,
  activeDebateRef,
  speakTimeLeft,
  remainingTime,
}) => {
  return (
    <div>
      <div className="speak_time_debate_state">
        {isLive &&
          debateState?.isStarted === false &&
          debateState?.hasFinished === false &&
          "Waiting for debators to start"}

        {isLive &&
          debateState?.isStarted &&
          debateState?.hasFinished === false &&
          "Ongoing"}
        {isLive && debateState?.hasFinished && "Completed"}
        {isLive === false && "Not Started"}
      </div>

      <div className="debate_room_top_header">
        <h1 className="Debate_room_main_text">
          {activeDebateRef.current?.topic}
        </h1>

        <h1 className="main_timing_text">
          This Debate {isLive ? "was" : "is"} Scheduled to start on <br />
          <p className="startTimeText">
            {moment(activeDebateRef.current?.startTime).format("LLLL")}
          </p>{" "}
          <br />
        </h1>

        {isLive ? (
          <>
            {debateState.hasFinished === false ? (
              debateState.isStarted &&
              (debateState?.isInterval === false ? (
                // if not interval
                <h1 className="main_timing_text">
                  {speakTimeLeft
                    ? ` TIME LEFT FOR THIS ROUND ${getTime(speakTimeLeft)}`
                    : ""}
                </h1>
              ) : (
                // if there's interval

                <div className={"intervalBox"}>
                  <strong>Sit back and relax its interval </strong>
                  <h1 className="main_timing_text">
                    {speakTimeLeft ? (
                      <strong>TIME LEFT {getTime(speakTimeLeft)}</strong>
                    ) : (
                      ""
                    )}
                  </h1>
                </div>
              ))
            ) : (
              <p className="main_timing_text"> Debate has finished</p>
            )}
          </>
        ) : remainingTime.day !== null ? (
          Boolean(
            parseInt(
              getTimeCountDown(
                null,
                remainingTime.day,
                remainingTime.hour,
                remainingTime.min,
                remainingTime.sec
              )
            )
          ) ? (
            <h1 className="main_timing_text">
              {getTimeCountDown(
                null,
                remainingTime.day,
                remainingTime.hour,
                remainingTime.min,
                remainingTime.sec
              ) + "to go "}
            </h1>
          ) : (
            ""
          )
        ) : (
          ""
        )}

    
      </div>
    </div>
  );
};

export default DebateRoomHeader;
