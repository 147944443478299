"use client";

import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { participateToTournamentApi } from "../../utils/Api";
import { useSelector } from "react-redux";
// import { HiOutlineExclamationCircle } from "react-icons/hi";

export function TournamentParticipateModal({
  children,
  motion,
  judge_name,
  start_date,
  team_id,
  team_name,
}) {
  const [openModal, setOpenModal] = useState(false);
  const { data } = useSelector((state) => state.user);

  const handleParticipate = async () => {
    if (!data || !motion?._id || !team_id) return;
    try {
      await participateToTournamentApi({
        team_id,
        motion_id: motion._id,
        participant_id: data?._id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <span className="cursor-pointer" onClick={() => setOpenModal(true)}>
        {children}
      </span>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            {/* <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" /> */}
            <div className="flex flex-col gap-y-5">
              <div className="flex flex-col gap-y-2 ">
                <div className="">
                  <p className="font-bold text-start justify">
                    {motion?.title}
                  </p>
                  <div className="flex flex-col justify-between gap-y-2">
                    <div className="flex border-b border-gray-300 py-3   ">
                      <div className=" ">
                        <p className="text-sm text-gray-600">Judge :&nbsp; </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">{judge_name}</p>
                      </div>
                    </div>
                    <div className="flex border-b border-gray-300 py-3">
                      <div>
                        <p className="text-sm text-gray-600">
                          Debate Type :&nbsp;{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">
                          {motion?.debate_type}
                        </p>
                      </div>
                    </div>
                    <div className="flex border-b border-gray-300 py-3">
                      <div>
                        <p className="text-sm text-gray-600">
                          Start Date :&nbsp;{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">{start_date}</p>
                      </div>
                    </div>
                    <div className="flex border-b border-gray-300 py-3">
                      <div>
                        <p className="text-sm text-gray-600">
                          Your role :&nbsp;{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600 uppercase">
                          {team_name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <p className="text-sm">
                    Are you sure you want to particpate from <b>CON</b> side ?
                  </p>
                </div> */}
              </div>
              <div className="text-xs text-gray-500">
                Note : You will be charged a small fee for participating the
                tournament as a debater to cover the judge fee.We will send you
                an invoice once the tournament is confirmed to take place .You
                will not be sent an invoice and charged if the tournament is
                cancelled.
              </div>
              <div className="flex justify-center gap-x-3 ">
                <Button className="bg-red-500" onClick={()=>setOpenModal(false)}>Cancel</Button>
                <Button className="bg-blue-500" onClick={handleParticipate}>
                  Participate
                </Button>
              </div>

              <div className="flex">
                {/* <div className=" rounded-md   bg-green-400 w-fit text-white">
                    {motion?.debate_type}
                  </div>
                  <div className="underline">Judge :{judge_name}</div> */}
              </div>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
