import React, { useEffect, useState } from "react";
import Navbar from "../../../Layouts/Navbar/Navbar";
import { Button, Label, Select, TextInput } from "flowbite-react";
import { MdDeleteOutline } from "react-icons/md";
import DatePicker from "react-datepicker";
import {
  createLiveJudgeTournamentApi,
  getDebaiCoachesApi,
} from "../../../utils/Api";
import {
  debateFormat,
  debateTeamFormat,
  TimeFormatMappingMethod,
} from "../../../utils/data";
import { useSelector } from "react-redux";
import useAlert from "../../../hook/useAlert";

const CreateTournament = () => {
  const { data } = useSelector((state) => state.user);
  const { open } = useAlert();
  const [tournamentDetails, setTournamentDetails] = useState({
    start_date: new Date(),
    judge_id: "",
    registration_deadline: new Date(),
    description: "",
    coaches: [],
    motions: [
      {
        id: 1,
        title: "",
        debate_type: "",
        teams: [],
        timeFormat: [],
      },
    ],
  });

  useEffect(() => {
    fetchAllCoaches();
  }, []);

  useEffect(() => {
    if (data && tournamentDetails.coaches.length > 0) {
      const isLoggedInUserCoach = tournamentDetails.coaches.some(
        (coach) => coach._id === data._id
      );
      if (isLoggedInUserCoach) {
        setTournamentDetails((prev) => ({
          ...prev,
          judge_id: data._id,
        }));
      }
    }
  }, [tournamentDetails.coaches, data]);
  useEffect(() => {
    const now = new Date(tournamentDetails.start_date);
    const oneDayEarlier = new Date(now);
    oneDayEarlier.setDate(now.getDate() - 1);
    setTournamentDetails((prev) => ({
      ...prev,
      registration_deadline: oneDayEarlier,
    }));
  }, [tournamentDetails.start_date]);

  useEffect(() => {}, [tournamentDetails.motions]);
  const fetchAllCoaches = async () => {
    try {
      const { data, status } = await getDebaiCoachesApi();

      if (status === 200) {
        setTournamentDetails((prev) => ({
          ...prev,
          coaches: data.message,
        }));
      }
    } catch (error) {
      //
    }
  };

  const handleNewMotion = () => {
    setTournamentDetails((prev) => ({
      ...prev,
      motions: [
        ...prev.motions,
        {
          id: prev.motions.length + 1,
          title: "",
          debate_type: "",
        },
      ],
    }));
  };
  const handleRemoveMotion = (id) => {
    console.log("removing", id);

    setTournamentDetails((prev) => ({
      ...prev,
      motions: prev.motions.filter((m) => m.id !== id),
    }));
  };

  const handleInputChangeForMotion = (name, value, id) => {
    const isDebateType = name === "debate_type";
    let timeFormat = [];
    let teams;
    setTournamentDetails((prev) => ({
      ...prev,
      motions: prev.motions.map((motion) => {
        if (motion.id === id) {
          if (isDebateType) {
            timeFormat = TimeFormatMappingMethod(null, value);
            teams = debateTeamFormat.get(value);
          } else {
            teams = motion.teams;
            timeFormat = motion.timeFormat;
          }

          return { ...motion, [name]: value, teams, timeFormat };
        } else {
          return motion;
        }
      }),
    }));
  };

  const handleDateChange = (name, value) => {
    const now = new Date(value);
    const oneDayEarlier = new Date(now);
    oneDayEarlier.setDate(now.getDate() - 1);
    setTournamentDetails((prev) => ({
      ...prev,
      [name]: new Date(value),
    }));
  };
  const handleInputChange = (name, value) => {
    setTournamentDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCreateTournament = async (e) => {
    e.preventDefault();

    try {
      const copyTournamentDetails = { ...tournamentDetails };
      delete copyTournamentDetails.coaches;
      const { status } = await createLiveJudgeTournamentApi(
        copyTournamentDetails
      );
      if (status === 201) {
        open({ type: "success", text: "Live Debate created successfully." });
      }
    } catch (error) {
      open({ type: "success", text: "Failed to create live debate." });
    }
  };

  console.log("total", tournamentDetails);
  return (
    <div className="h-screen">
      <div className="flex w-[95%] mx-auto py-5 gap-x-5 h-full">
        <div className="flex-[3] shadow-md rounded-md p-5 ">
          <div className="flex flex-col gap-y-2">
            <h1 className="font-bold text-xl">Tournament Details ?</h1>
            <div className="flex items-center gap-x-1">
              <p className="text-medium">Jugde :</p>
              <p>Judge Name</p>
            </div>

            <div className="flex items-center gap-x-1">
              <p>Registration </p>
              <p>{}</p>
            </div>
            <div className="flex items-center gap-x-1">
              <p>No of Motions</p>
              <p>{tournamentDetails.motions.length}</p>
            </div>
          </div>
        </div>
        <form
          className="flex-[9] flex flex-col gap-y-4"
          onSubmit={handleCreateTournament}
        >
          <div>
            <h1 className="font-bold text-xl">Fill Tournament Form</h1>
          </div>
          <div className="flex gap-x-4">
            <div className="flex-[1]">
              <div className="mb-2 block">
                <Label htmlFor="judge_id" value="Select Judge" />
              </div>
              <Select
                id="judge_id"
                required
                value={tournamentDetails.judge_id}
                disabled={tournamentDetails.judge_id === data?._id}
                onChange={(e) => handleInputChange("judge_id", e.target.value)}
              >
                <option value={""}>Select Judge</option>
                {tournamentDetails.coaches.map((coach) => (
                  <option value={coach._id}>
                    {coach.firstName} {coach.lastName || ""}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex-[1]">
              <div className="mb-2 block">
                <Label htmlFor="start_date" value="Start Date" />
              </div>
              <div className="block  w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm rounded-lg">
                <DatePicker
                  selected={tournamentDetails.start_date}
                  onChange={(date) => handleDateChange("start_date", date)}
                  showTimeInput
                  timeInputLabel="Time:"
                  dateFormat="yyyy/MM/dd , h:mm aa"
                  className="h-[20px] py-0 bg-transparent outline-none focus-visible:bg-border-none border-none text-sm"
                />
              </div>
            </div>
            <div className="w-14  h-5"></div>
          </div>
          <div className="flex gap-x-4">
            <div className="flex-[1]">
              <div className="mb-2 block">
                <Label htmlFor="=coach" value="Registration Deadline" />
              </div>
              <div className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm rounded-lg">
                <DatePicker
                  selected={tournamentDetails.registration_deadline}
                  onChange={(date) =>
                    handleDateChange("registration_deadline", date)
                  }
                  showTimeInput
                  timeInputLabel="Time:"
                  dateFormat="yyyy/MM/dd , h:mm aa"
                  className="h-[20px] py-0 bg-transparent outline-none border-none text-sm"
                />
              </div>
            </div>
            <div className="flex-[1]">
              <div className="mb-2 block">
                <Label htmlFor="countries" value="Description" />
              </div>
              <TextInput
                id="small"
                type="text"
                sizing="md"
                placeholder={`Write extra information about the tournament ...`}
                className="flex-[1]"
                value={tournamentDetails.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
            </div>
            <div className="w-14  h-5"></div>
          </div>
          <div>
            <h1 className="font-bold">Add Motions for the tournaments</h1>
          </div>
          {tournamentDetails.motions.map((motion, index) => {
            return (
              <div className="flex gap-x-4 ">
                <div className="flex-[1]">
                  <div className="mb-1 block">
                    <Label htmlFor="small" value="Debate Motion" />
                  </div>
                  <TextInput
                    id="small"
                    type="text"
                    sizing="md"
                    placeholder={`Enter motion ${index + 1}`}
                    className="flex-[1]"
                    onChange={(e) =>
                      handleInputChangeForMotion(
                        "title",
                        e.target.value,
                        motion.id
                      )
                    }
                    value={motion.title}
                  />
                </div>
                <div className="flex-[1]">
                  <div className="mb-2 block">
                    <Label htmlFor="debateType" value="Select judge type" />
                  </div>
                  <Select
                    id="debateType"
                    required
                    value={motion.debate_type}
                    onChange={(e) =>
                      handleInputChangeForMotion(
                        "debate_type",
                        e.target.value,
                        motion.id
                      )
                    }
                  >
                    <option value="">Select debate type</option>
                    {Array.from(debateFormat).map(([debateType]) => (
                      <option value={debateType} key={debateType}>
                        {debateType}
                      </option>
                    ))}
                  </Select>
                </div>
                {index !== 0 ? (
                  <Button
                    className="self-end"
                    color={"red"}
                    onClick={() => handleRemoveMotion(motion.id)}
                  >
                    <MdDeleteOutline />
                  </Button>
                ) : (
                  <div className="w-14  h-5"></div>
                )}
              </div>
            );
          })}
          <div className="flex gap-x-4 justify-start  w-full">
            <Button color={"light"} onClick={handleNewMotion}>
              Add New Motion
            </Button>
            <Button color="blue" type="submit">
              Create Tournament
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTournament;
