import styles from "./DebateCompletionUi.module.css";
import { useEffect, useState } from "react";
import MysteryBox from "../MysteryBox/MysteryBox";
import { useParams } from "react-router-dom";
import { getDebateByIdApi } from "../../../utils/Api";
import { useSelector } from "react-redux";
import { getMyTeam } from "../../../utils/services";
import { Enums } from "../../../redux/action/actionTypes/Enumss";
import { debateFormatEnum } from "../../../utils/enum";

const DebateCompletionUi = () => {
  const { data: currentUser } = useSelector((state) => state.user);
  const [activeDebate, setActiveDebate] = useState(null);
  const [debateResult, setDebateResult] = useState("");
  const [isAudience, setIsAudience] = useState(true);
  const { debateId } = useParams();

  useEffect(() => {
    if (!debateId) return;
    fetchDebateById();
  }, [debateId]);
  useEffect(() => {
    console.log("insider");
    if (!currentUser) {
      return setDebateResult("AUDIENCE");
    }
    if (activeDebate && currentUser) {
      const { winner, teams, type, results, judgeType } = activeDebate;
      const myTeamName = getMyTeam(teams, currentUser?._id)?.name;

      if (winner === Enums.INVALID) {
        return setDebateResult(Enums.INVALID);
      } else if (judgeType === Enums.NOJUDGE) {
        return setDebateResult(Enums.NOJUDGE);
      }

      console.log("my team",myTeamName)
      if (!myTeamName) {
        return setDebateResult("AUDIENCE");
      } else {
        setIsAudience(false);
      }

      if (type === debateFormatEnum["British Parliamentary"] && results) {
        let position_of_team;
        Object.entries(results).forEach(([position, team]) => {
          if (team === myTeamName) {
            position_of_team = position;
          }
        });
        setDebateResult(position_of_team);
      } else if (myTeamName?.toLowerCase() === winner?.toLowerCase()) {
        setDebateResult(Enums.WON);
      } else if (Enums.MATCH_TIED === winner) {
        setDebateResult(Enums.TIED);
      } else {
        setDebateResult(Enums.LOSE);
      }
    }
  }, [activeDebate, currentUser]);

  const fetchDebateById = async () => {
    try {
      const { data, status } = await getDebateByIdApi(debateId);
      if (status === 200) {
        setActiveDebate(data.message[0]);
      }
    } catch (error) {}
  };
  return (
    <div className={styles.finishModalContainer}>
      <div className={styles.modalBodyContent}>
        <MysteryBox
          debateResult={debateResult}
          activeDebate={activeDebate}
          isAudience={isAudience}
        />
      </div>
    </div>
  );
};

export default DebateCompletionUi;
