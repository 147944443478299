import { useEffect, useRef, useState } from "react"
import styles from "./Maintainance.module.css"
import { updateWebsiteInfoApi } from "../../../utils/Api";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../redux/store";
import { bindActionCreators } from "redux";

const TermsAndConditions = ( {info}) => {
  const debateInputRef = useRef();
  const [updateMode,setUpdateMode] =useState(false)
  const [defaultValue,setDefaultValue] =useState("")
      const dispatch=useDispatch()
    const {SetRefreshNow} = bindActionCreators(actionCreators,dispatch )
  useEffect(()=>{
    setDefaultValue(info?.termsAndConditions)
  },[info])
  const handleSubmit=async()=>{

    const value = debateInputRef.current.value; 
    try {
      if(value){
       await updateWebsiteInfoApi({
          termsAndConditions:value
        })
        SetRefreshNow()
        setUpdateMode(false)
      }
      
    } catch (error) {
      
    }

  }
  return (
    <div>   
      <div className={styles.maintainanceHeaderBox}>

        <h2 className={styles.maintainanceHeaderText}>Terms And Conditions</h2>

    <button className={styles.headerButton} onClick={()=>setUpdateMode(!updateMode)}>
      {updateMode? "Close":"Update"}
    </button>
      </div>
     <div className={styles.maintainanceMainText}>
      {
        info?.termsAndConditions
      }
      </div>
          <div className={`${styles.updateBox} ${updateMode ? styles.updateMode :""}`}>
            
        <textarea  ref={debateInputRef} defaultValue={defaultValue} ></textarea>
        <button  className={styles.updateButton} onClick={handleSubmit}> Update Terms And Conditions </button>
        </div>

    </div>
  )
}

export default TermsAndConditions