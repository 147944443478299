import React from "react";
import JudgeDebateActionButtonsUi from "./JudgeDebateActionButtonsUi";
import { JudgeDebateReducerEnum } from "../../../utils/enum";

const JudgeDebateActionButtonsLogic = ({
  dispatch,
  hasStarted,
  isMicMuted,
  debateState,
  JudgeDebate,
}) => {
  const handleCloseMic = () => {
    dispatch({
      type: JudgeDebateReducerEnum.CLOSE_MIC,
    });
  };
  const handleOpenMic = () => {
    dispatch({
      type: JudgeDebateReducerEnum.OPEN_MIC,
    });
  };
  const handleStartDebate = async () => {
    await JudgeDebate.startDebate();
  };

  return (
    <JudgeDebateActionButtonsUi
      hasStarted={hasStarted}
      handleCloseMic={handleCloseMic}
      handleOpenMic={handleOpenMic}
      handleStartDebate={handleStartDebate}
      isMicMuted={isMicMuted}
      isLive={debateState.isLive}
    />
  );
};

export default JudgeDebateActionButtonsLogic;
