import React, { useEffect, useState } from "react";
import styles from "./CoachDecision.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DecisionBox from "../DecisionBox/DecisionBox";
import { debateFormatEnum } from "../../../utils/enum";
import { positionIcons } from "../../../utils/data";

const CoachDecision = ({
  coach,
  activeDebate,
  showResult,
  RoomService,
  startCoachDecision,
  setResult,
  winTeamName,
  setWinTeamName,
  debateState,
  result,
}) => {
  const [isCoach, setIsCoach] = useState(null);
  const { data } = useSelector((state) => state.user);

  useEffect(() => {
    if (data) {
      setIsCoach(coach.some((ch) => ch.id === data._id));
    } else {
      setIsCoach(false);
    }
  }, [data, coach]);

  console.log("is coach", isCoach);
  return (
    <div className={styles.CoachDecision}>
      {isCoach && debateState?.isStarted && (
        <div className={styles.top_header}>
          <img
            width="60"
            height="60"
            src="/images/decision.png"
            alt="decision--v1"
          />
          <h1 className={styles.main_text}>DEBATE WINNER DECISON </h1>
        </div>
      )}
      {showResult && (
        <div className={styles.decision_header}>
          <WinnerTeamComp
            winnerTeam={winTeamName}
            isCoach={isCoach}
            result={result}
            activeDebate={activeDebate}
            debateId={activeDebate?._id}
          />
        </div>
      )}
      {!showResult && !isCoach && startCoachDecision && (
        <div>
          <p className={styles.desc_text}>
            Coach are deciding the winner wait for a while ...
          </p>
          <svg
            className={styles.loadingSvg}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            id="loading"
          >
            <path
              fill="#414042"
              d="M29.89 15.81a2.51 2.51 0 1 0-5 .45 9.65 9.65 0 0 1-1.68 6.34 10.24 10.24 0 0 1-5.74 4 10.71 10.71 0 0 1-7.38-.7 11.44 11.44 0 0 1-5.48-5.62A12.07 12.07 0 0 0 9.46 27 12.58 12.58 0 0 0 17.9 29a13.31 13.31 0 0 0 8.18-4 14 14 0 0 0 3.81-8.75v-.08A2.29 2.29 0 0 0 29.89 15.81zM7.11 15.74A9.65 9.65 0 0 1 8.79 9.4a10.24 10.24 0 0 1 5.74-4 10.71 10.71 0 0 1 7.38.7 11.44 11.44 0 0 1 5.48 5.62A12.07 12.07 0 0 0 22.54 5 12.58 12.58 0 0 0 14.1 3 13.31 13.31 0 0 0 5.92 7a14 14 0 0 0-3.81 8.75v.08a2.29 2.29 0 0 0 0 .37 2.51 2.51 0 1 0 5-.45z"
              data-name="Looding 19"
            ></path>
          </svg>
        </div>
      )}
      {isCoach && !showResult && (
        <DecisionBox
          RoomService={RoomService}
          result={result}
          setResult={setResult}
          activeDebate={activeDebate}
          setWinTeamName={setWinTeamName}
          winTeamName={winTeamName}
          debateState={debateState}
        />
      )}
    </div>
  );
};

export default CoachDecision;

export const WinnerTeamComp = ({
  winnerTeam,
  activeDebate,
  debateId,
  isCoach,
  result,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (debateId) {
      navigate(`/completion/${debateId}`);
    }
  };

  return (
    <>
      <div className={styles.winnerTeamComp}>
        <div className={styles.winnerBox}>
          {activeDebate?.type === debateFormatEnum["British Parliamentary"] ? (
            <>
              <div className="flex gap-x-5   mx-auto">
                {Object.keys(result).map((position) => {
                  return (
                    <>
                      <div className="flex items-center shadow-md rounded-md h-[80px] justify-center gap-x-5 px-10">
                        <img
                          width={"30"}
                          height={"30"}
                          src={positionIcons[position]}
                          alt={position}
                        />
                        <div className="flex items-center gap-x-2">
                          <img
                            width="30"
                            height="30"
                            src="https://img.icons8.com/3d-fluency/94/user-group-man-woman--v1.png"
                            alt="user-group-man-woman--v1"
                          />
                          <p className="font-bold text-2xl ">
                            {result[position]}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div>
                <img
                  width="94"
                  height="94"
                  src="https://img.icons8.com/3d-fluency/94/confetti.png"
                  alt="confetti"
                />
                <h1 className={styles.winText}>
                  <h1 className={styles.winnerName}>{winnerTeam}</h1> won the
                  debate !!
                </h1>
              </div>
            </>
          )}
        </div>
        {/* <button className={styles.claimButton} onClick={handleNavigate}>
          {isCoach ? "GOTO COMPLETION PAGE" : " CLAIM MYSTERY BOX AND FINISH"}
        </button> */}
      </div>
    </>
  );
};
